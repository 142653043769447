<template>
    <div>
        <p><strong>球狮体育会员服务协议</strong></p>
        <p>欢迎您开通球狮体育会员。</p>
        <p>请您务必审慎阅读、充分理解本协议各条款内容，特别是免除或限制责任的相应条款。球狮体育将以加粗或斜体加粗等形式提示您注意限制、免责条款。&nbsp;未成年人应在法定监护人的陪同下阅读本协议。</p><br />
        <p>您以任何形式获得并使用球狮体育会员资格，都视为您已阅读并同意接受本协议的约束。如果您对本协议的任何条款表示异议，您可以选择不开通本产品。</p><br />
        <p><strong>服务内容</strong></p>
        <p>球狮体育会员是球狮体育为用户打造的增值产品，包含多种专有的会员权益。您使用球狮体育时的设备或软件版本型号，可能影响您正常享受球狮体育会员权益。当因前述情况出现时，我们将建议您更换设备或提醒您及时升级，以免影响您正常享受全部会员权益。
        </p>
        <br />
        <p>球狮体育会员所包含的会员权益以球狮体育在会员服务页面公布的信息为准。为免疑义，除非上述页面有相反说明，会员权益不包括「免广告」或类似功能，也不意味着可免费访问球狮体育站内全部内容。球狮体育有权根据业务发展情况调整全部或部分会员权益。前述调整将在上述会员服务页面进行公告，您也可以随时意见反馈了解当下会员权益的具体情况。
        </p><br />
        <p><strong>开通和终止</strong></p>
        <p>您同意本协议并支付会员费用或通过使用兑换码等其他方式，即可获得球狮体育会员资格（以下简称「会员资格」）。</p>
        <p>球狮体育会员有效期：自会员资格开通之日起算，具体有效期限可以在会员个人页面查看。会员在会员有效期内续费或通过其他方式续展会员期限，有效期将相应延长。会员在会员资格到期后未以任何方式续展期限，球狮体育有权停止提供相应会员权益。
        </p>
        <p>您在开通球狮体育会员时，应仔细核对帐号信息、会员期限、会员费用等信息。由于球狮体育会员是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，球狮体育不能退还相应会员费。
        </p>
        <p>会员未使用球狮体育会员权益的，不视为放弃相应权利。但会员如果违反球狮体育社区各项管理规定，或会员帐号有异常情况，球狮体育有权根据管理规定对相应会员帐号采取限制或冻结措施。此种情况下，会员的帐号功能及会员权益将受到限制。
        </p>
        <p><strong>会员费用及续费</strong></p>
        <p>球狮体育会员费以您购买球狮体育会员时，球狮体育在会员服务页面标示的价格为准。球狮体育将不时推出优惠活动，并在会员服务页面公示活动信息及活动价格。</p>
        <p><strong>会员帐号管理</strong></p>
        <p>会员应合理使用会员权益、享受会员服务。球狮体育将通过页面公告或私信等方式向会员发布通知。会员应及时关注相关信息的更新。</p>
        <p>会员应遵守球狮体育的各项规则，包括但不限于本协议，以及《用户协议》《隐私协议》&nbsp;等制度规范，以及球狮体育不时发布的与球狮体育用户和球狮体育会员相关的各项通知、公告（本协议内统称&ldquo;管理规定&rdquo;）。
        </p>
        <p>因不能归责于球狮体育的原因导致的会员帐号密码泄露或会员权益受损，球狮体育不承担任何责任。会员发现帐号被盗，应及时通过管理规定找回帐号。为避免帐号盗用引起的风险，球狮体育亦有权主动限制、冻结&nbsp;帐号，暂停或终止为异常帐号提供会员权益。
        </p>
        <p>如果会员因违反球狮体育管理规定而被限制帐号权限（包括禁言、帐号锁定等），其会员资格依旧有效，但所享有的相关会员权益将受到一定限制。</p>
        <p>会员不得以不正当手段获取会员资格，不得将会员帐号以出借、出租、出售等任何形式提供给第三方使用，不得以任何形式向第三方分享或售卖其所享有的会员权益，不得滥用会员权益，不得利用会员资格获取不正当利益。如会员存在不当行为，违反管理规定，或以任何形式损害球狮体育或其他球狮体育用户利益的，球狮体育有权暂停或取消该会员的会员资格，且不退还已收取的会员费。此外，违规会员需承担因此引起的一切责任和后果。
        </p>
        <p>因可归责于会员自身的原因引发的纠纷均与球狮体育无关，会员应当自行承担相应责任。如果该等纠纷损害球狮体育的合法权益，球狮体育有权取消该会员的会员资格，且不退还已收取的会员费。同时，球狮体育有权以合法手段维护自身权益。
        </p>
        <p>隐私协议：<a href="https://www.qiukedao.net/#/qiushi/privacyPolicy"><u>隐私政策</u></a></p>
        <p>会员协议：<a href="https://www.qiukedao.net/#/qiushi/vipService"><u>协议地址</u></a></p>
        <p><strong>协议修改</strong></p>
        <p>根据互联网的发展和有关法律、法规及规范性文件的变化，或者因球狮体育业务发展需要，球狮体育有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，球狮体育将根据实际情况选择以包括但不限于系统提示、公告、站内信等方式提示您。
        </p><br />
        <p><strong>适用法律及争议解决</strong></p>
        <p>本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。如本协议的任何约定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将按照尽可能接近本协议原条文的目的进行重新解释，本协议其它约定仍应具有完整的效力及效果。
        </p>
        <p>本协议的签订地为中华人民共和国宁波市。若你因本协议与公司发生任何争议，双方应尽量友好协商解决；如协商不成的，你同意应将相关争议提交至本协议签订地的人民法院诉讼解决。</p>
        <p><strong>其他</strong></p>
        <p>如因不可抗力或其他球狮体育无法控制的原因，导致球狮体育不能正常提供会员权益的，球狮体育将及时发布通知并合理处理善后事宜，尽量降低会员的损失。</p>
        <p>如果您对本协议内容有任何疑问，请发送邮箱：120329298@qq.com</p>
    </div>
</template>
<script>
</script>