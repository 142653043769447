<template>
    <div>
        <p>尊敬的用户，您好。</p>
        <p>在同意&nbsp;《用户充值协议》&nbsp;（下称：本协议）前，请您认真阅读并充分知悉、理解球狮体育平台的各项规则及要求（包括但不限于《服务协议》、《隐私政策》），以及国家关于互联网信息服务的有关法律法规等。如果您对本协议的任何条款表示异议，您可以选择不使用球狮体育平台中与账户充值相关的所有功能；您的&nbsp;使用则意味着，您同意遵守本协议下全部内容，以及我们后续对本协议随时所作的任何修改。
        </p>
        <p>请确认，您应当具有完全民事行为能力，且已年满18周岁（或已年满16周岁且依靠自己的劳动收入作为主要生活来源），否则，请您停止使用本协议项下与账户充值相关的所有功能。</p>
        <p>第一章&nbsp;总则</p>
        <p>第1条&nbsp;球狮体育平台是广西知音文化传媒有限公司推出的一个更方便地进行赛事资讯、赛事观看（动画）、赛事交流、赛事分析等服务并与用户进行互动的赛事社交互动平台。球狮体育平台将为用户提供虚拟产品、用户社区、数据支持、技术支持等赛事相关互动社交所需的服务。
        </p>
        <p>第2条&nbsp;球狮体育平台所有权、经营权、管理权均属广西知音文化传媒有限公司。</p>
        <p>第3条&nbsp;本协议最终解释权归属广西知音文化传媒有限公司。</p>
        <p>第二章&nbsp;虚拟产品购买规则</p>
        <p>第4条&nbsp;&ldquo;球币&rdquo;是球狮体育平台推出的虚拟消费产品。您可以用&ldquo;球币&rdquo;在球狮体育平台上兑换和体验不同类型的服务。&nbsp;您所购买获得的&ldquo;球币&rdquo;不能兑换为人民币。
        </p>
        <p>第5条&nbsp;您暂时只能用移动端应用（球狮体育APP），在iOS平台通过苹果内购支付，在安卓平台通过微信、支付宝等第三方支付平台，以人民币充值并购买虚拟产品。</p>
        <p>第6条&nbsp;球狮体育平台保留根据相关法律规定、主管部门要求、业务开展情况等因素对使用人民币购买虚拟产品的规则进行单方面变更、调整、中止或终止的权利。您同意无条件接受对上述购买规则的变更、调整、中止或终止，&nbsp;球狮体育平台开展前述行动时将以于球狮体育平台公布的方式通知，并自公布之日起自动生效，而无需另行单独通知您，也不就该等行动给您造成的任何损失承担任何责任。
        </p>
        <p>第7条&nbsp;在使用充值方式时，您务必仔细确认自己的球狮体育账号并仔细选择您需要充值购买的虚拟产品数量。&nbsp;若因为您自身登录错误的账号、操作不当或不了解充值计费方式等因素造成充错账号、错选充值类型等情形而损害自身权益的，球狮体育平台将不会作出任何补偿、赔偿或退款。
        </p>
        <p>第8条&nbsp;若您以非法的方式，或使用非球狮体育平台所指定的充值方式进行虚拟产品的购买，球狮体育平台不保证该充值流程顺利或者正确完成。&nbsp;若因此造成您权益受损，球狮体育平台不会作出任何补偿、赔偿或退款，球狮体育平台同时保留随时冻结或终止您球狮体育账号资格及使用充值服务的权利。
        </p>
        <p>第9条&nbsp;充值或购买成功后，球狮体育账号内增加的虚拟产品数量，可由您在球狮体育平台上自由使用。原则上，对于充值或购买的虚拟产品，球狮体育平台不会提供任何退款或逆向兑换服务，但同时满足以下条件的用户充值或购买行为除外：
        </p>
        <p>（1）充值或购买平台为&ldquo;球狮体育&rdquo;安卓系统App；</p>
        <p>（2）充值或购买行为系用户的首次同类行为，例如：用户A在球狮体育平台首次充值成功10球币，该笔充值在满足其他必要条件的情况下可申请退款；用户A第二次充值30球币，则该笔充值不可申请退款；</p>
        <p>（3）充值或购买的虚拟产品为&ldquo;球币&rdquo;；</p>
        <p>（4）充值或购买虚拟产品之日起（从次日开始计算至您发起有关申请或联系之日）不超过10个自然日；</p>
        <p>（5）充值或购买后，用户未进行任何平台内的使用或其他消费。&nbsp;针对同时满足以上条件的充值或购买行为，用户可联系球狮体育平台（联系方式详见《隐私政策》），申请退款。球狮体育平台将收到用户申请后15日内审核用户身份信息以及用户购买记录，并给予用户是否退款的答复。&nbsp;请注意，由于iOS应用市场的特殊政策，球狮体育iOS客户端用户请联系iOS系统应用市场方向其申请退款。球狮体育iOS客户端的退款条件与规则由iOS应用市场确定，我们不能确保您的申请能够得到该应用市场的支持。
        </p>
        <p>第10条&nbsp;如果球狮体育平台发现因系统故障或其他任何原因导致的处理错误，无论有利于球狮体育平台还是有利于您，球狮体育平台都有权在以电子邮件、微信或其他合理方式通知您后纠正该错误。如果该措施导致您实际收到的虚拟产品的数量少于您应获得的虚拟产品数量，则球狮体育平台在确认该处理错误后会尽快将差额补足至您的球狮体育账户。如果该错误导致您实际收到的虚拟产品的数量多于您应获得的虚拟产品，则无论错误的性质和原因如何，球狮体育平台有权从您的球狮体育账户中直接扣除差额。
        </p>
        <p>第三章&nbsp;权利声明</p>
        <p>第11条&nbsp;您可随时登录球狮体育平台查看您的虚拟资产的剩余数量情况。如您对该记录有异议，应立即向球狮体育平台提出，球狮体育平台核对后确有错误的，将予以更正；否则您同意球狮体育平台上的交易记录将作为球狮体育平台虚拟产品交易的唯一有效依据。
        </p>
        <p>第12条&nbsp;球狮体育平台有权基于交易安全等方面的考虑不时设定、调整涉及交易的相关事项，包括但不限于交易限额、交易次数等。</p>
        <p>第13条&nbsp;在任何情况下，对于您购买虚拟产品时涉及由第三方提供相关服务的责任由该第三方承担，球狮体育平台不承担该等责任。</p>
        <p>第14条&nbsp;进行充值时，您应确保您是绑定的支付宝、苹果支付等第三方支付账户的持有人，可合法、有效使用该账户且未侵犯任何第三方合法权益，否则&nbsp;因此造成支付宝、苹果支付等第三方支付账户实际所有人损失的，您应单独负责解决由此产生的纠纷并承担全部法律责任。
        </p>
        <p>第15条&nbsp;因您自身的原因导致球狮体育平台无法提供球狮体育平台虚拟产品购买服务或提供虚拟产品购买服务时发生任何错误而产生的任何损失或责任，由您自行负责，球狮体育平台不承担责任，&nbsp;包括但不限于：</p>
        <p>（1）您未按照本协议或球狮体育平台不时公布的任何规则进行操作；</p>
        <p>（2）因您的球狮体育账号失效、丢失、被封停；</p>
        <p>（3）因您绑定的第三方支付机构账户的原因导致的损失或责任，包括您使用未经认证的第三方支付账户或使用非您本人的第三方支付账户，您的第三方支付账户被冻结、查封等；</p>
        <p>（4）您将密码告知他人导致的财产损失；</p>
        <p>（5）因您个人的故意或重大过失所造成的财产损失。</p>
        <p>第16条&nbsp;球狮体育平台系统因下列状况无法正常运作，使您无法使用各项服务或任何虚拟财产丢失时，广西知音文化传媒有限公司不承担损害赔偿责任，&nbsp;该状况包括但不限于：</p>
        <p>（1）在球狮体育平台公告之系统停机维护、升级、调整期间；</p>
        <p>（2）电信通讯或其他网络设备出现故障不能进行数据传输的；</p>
        <p>（3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击、政府管制等不可抗力之因素，造成球狮体育平台系统障碍不能执行业务的；</p>
        <p>（4）由于计算机病毒、黑客攻击、电信部门技术调整或故障、网站升级、相关第三方的问题等原因而造成的服务中断或者延迟。</p>
        <p>第四章&nbsp;处罚规则</p>
        <p>第17条&nbsp;如发生下列任何一种情形，球狮体育平台有权随时中断或终止向您提供本协议项下的网络服务而无需通知您：</p>
        <p>（1）您提供的个人资料不真实；</p>
        <p>（2）您违反本协议中第二章规定的购买规则。</p>
        <p>除前款所述情形外，球狮体育平台同时保留在不事先通知您的情况下随时中断或终止部分或全部网络充值服务的权利。对于因本条前款所述之情形，导致的充值服务的中断或终止而造成的任何损失，球狮体育平台无需对您或任何第三方承担任何责任。
        </p>
        <p>第18条&nbsp;如果用户通过其它非球狮体育平台认可的渠道非法购买球币，则球狮体育平台有权冻结该账户，并进行相应惩罚，严重者可以进行封号处理。</p>
        <p>第19条&nbsp;用户在使用球狮体育平台时，如出现违反国家法律法规、《服务协议》约定、本协议约定或其他球狮体育平台对用户的管理规定的情形，球狮体育平台有权暂时或永久封禁您的账号。账号封禁后至解禁（如有）前，您不可继续使用您在平台上已经充值购买的虚拟产品或服务，同时球狮体育不予返还您购买相关虚拟产品或服务时的现金价值。
        </p>
        <p>第五章&nbsp;附则</p>
        <p>第20条&nbsp;球狮体育平台保留修改或增补本协议内容的权利。本协议的修改文本将公告于球狮体育平台或以其他球狮体育平台认为可行的方式公告。修改文本一旦公布则立即生效，且对生效前的用户同样适用。若您在本协议内容发生修订后，继续使用本服务的，则视为您同意最新修订的协议内容；否则您须立即停止使用本服务。
        </p>
        <p>第21条&nbsp;因本协议引起的或与本协议有关的争议，均适用中华人民共和国法律。</p>
        <p>第22条&nbsp;因本协议引起的或与本协议有关的争议，球狮体育平台与您将协商解决。协商不成的，任何一方均有权向广西知音文化传媒有限公司所在地有管辖权的法院提起诉讼。</p>
        <p>第23条&nbsp;本协议部分内容被有管辖权的法院认定为违法或无效的，不因此影响其他内容的效力。</p>
        <p>第24条&nbsp;本协议项下所有的通知均可通过球狮体育平台重要页面公告、站内信、电子邮件或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</p>
        <p>第25条&nbsp;本协议签订地：广西知音文化传媒有限公司。</p>
        <p>（以下无正文）</p>
        <p>附件一：球狮体育平台虚拟资产充值价格</p>
        <p>（2023&nbsp;年&nbsp;11&nbsp;月&nbsp;27&nbsp;日生效）</p>
        <p>球币</p>
        <p>安卓系统</p>
        <table>
            <tbody>
                <tr>
                    <td width="495">
                        <p>人民币</p>
                    </td>
                    <td width="495">
                        <p>球币</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>6&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>6&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>30&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>30&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>60&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>60&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>88&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>88&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>188元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>188个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>618元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>618&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>988元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>988个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>1988元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>1988个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>3988&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>3988个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>N元（N&ge;&nbsp;1）以上自定义人民币兑换</p>
                    </td>
                    <td>
                        <p>&nbsp;</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>iOS苹果系统</p>
        <table>
            <tbody>
                <tr>
                    <td width="495">
                        <p>人民币</p>
                    </td>
                    <td width="495">
                        <p>球币</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>6&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>6&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>30&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>30&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>60&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>60&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>88&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>88&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>188元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>188个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>618元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>618&nbsp;个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>988元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>988个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>1988元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>1988个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>3988&nbsp;元人民币兑换</p>
                    </td>
                    <td width="495">
                        <p>3988个&ldquo;球币&rdquo;</p>
                    </td>
                </tr>
                <tr>
                    <td width="495">
                        <p>N元（N&ge;&nbsp;1）以上自定义人民币兑换</p>
                    </td>
                    <td>
                        <p>&nbsp;</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
  name: 'PayPolicy'
}
</script>