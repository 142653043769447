<template>
    <div>
        <p>隐私政策</p>
        <p>更新日期：2024年10月30日</p>
        <p>生效日期：2024年10月30日</p>
        <p>【前言】</p>
        <p>欢迎使用&ldquo;球狮体育&rdquo;（以下统称&ldquo;我们&rdquo;）提供的服务！我们深知个人信息对您的重要性，并庄严承诺保护使用球狮体育的用户（以下统称&ldquo;用户&rdquo;或&ldquo;您&rdquo;）的个人信息及隐私安全。&nbsp;<strong>您在使用球狮体育时，我们可能会收集和使用您的相关个人信息（以下统称为&ldquo;信息&rdquo;）。</strong>&nbsp;我们希望通过《隐私政策》（以下简称&ldquo;《隐私政策》&rdquo;）向您说明我们在收集和使用您的个人信息时对应的处理规则等相关事宜，以便更好地保障您的权益。
        </p>
        <p>【特别提示】</p>
        <p>请您在使用球狮体育前，仔细阅读（未成年人请在监护人陪同下阅读）并了解《隐私政策》，以作出适当选择。&nbsp;<strong>当您同意《隐私政策》时，即表示您已同意我们按照《隐私政策》处理您的相关个人信息。&nbsp;除非您已阅读并接受《隐私政策》所有条款，否则您不应使用球狮体育。</strong>
        </p>
        <p><strong>我们的产品/服务中包含的或链接至第三方提供的信息与/或第三方服务（包括任何第三方应用、网站、产品、服务等），&nbsp;这些信息/服务由第三方负责运营，您使用该信息/服务适用第三方另行向您说明的个人信息收集、使用的规则，不适用《隐私政策》。</strong>
        </p>
        <p>目录</p>
        <p>一、定义</p>
        <p>二、我们如何收集和使用您的个人信息</p>
        <p>三、我们如何使用COOKIES或同类技术</p>
        <p>四、我们可能分享、转让和披露的信息</p>
        <p>五、我们如何保留、储存信息</p>
        <p>六、我们如何保护信息安全</p>
        <p>七、您对个人信息享有的权利</p>
        <p>八、第三方服务</p>
        <p>九、未成年人个人信息保护条款</p>
        <p>十、通知和修订</p>
        <p>十一、如何联系我们</p>
        <p>十二、APP运营者基本信息</p>
        <p>一、定义</p>
        <p>我们的产品/服务：包括但不限于我们提供的&ldquo;球狮体育&rdquo;软件、iOS和安卓平台移动应用（以下简称&ldquo;APP&rdquo;）、网站、微信小程序、公众号，以及其他服务（含站外服务，例如：我们的广告服务和《隐私政策》第八条&ldquo;第三方服务&rdquo;中提及的插件等）
        </p>
        <p><strong>个人信息：是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。&nbsp;《隐私政策》中涉及的个人信息包括：个人身份信息（包括真实姓名、身份证件类型、证件号码以及人脸识别信息）；&nbsp;个人基本信息（包括生日、性别、手机号、电子邮箱）；&nbsp;个人虚拟财产信息（交易和消费记录、球币及球狮体育会员等级、会员时限）；&nbsp;个人金融信息（包括银行卡号、开户行、银行卡预留手机号）。</strong>
        </p>
        <p><strong>个人敏感信息</strong>：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或者歧视性待遇等的个人信息。</p>
        <p><strong>网络信息</strong>：包括WLAN接入点、基站等传感器信息、IP地址、接入网络的方式、网络质量数据、移动网络运营商信息等。</p>
        <p><strong>设备信息</strong>：包括硬件型号、设备MAC地址、操作系统类型、浏览器类型、软件列表、设备传感器数据（加速传感器）、唯一设备识别码（如Android&nbsp;ID/IMEI/IDFA在内的描述个人常用设备基本情况的信息）。&nbsp;以上设备信息无法单独用于识别您的身份。如果我们将以上信息与其他信息结合用于识别您的身份，或者将其与个人信息结合使用，则在结合使用期间，以上信息将被视为个人信息，除取得您授权或法律法规另有规定外，&nbsp;我们会将收集、使用的以上设备信息进行去标识化处理，且将仅使用特定的、无法直接关联您身份的编码进行数据分析等处理活动，不会与您的真实身份相关联。
        </p>
        <p><strong>地理位置信息</strong>：反映设备所在位置的有关信息，包括通过GPS信息、WLAN接入点、&nbsp;蓝牙和基站等传感器信息获取到的精确地理位置信息及通过其他网络信息（例如IP地址）获取的大致地理位置信息。
        </p>
        <p><strong>儿童</strong>：指不满十四周岁的未成年人。</p>
        <p><strong>未成年人</strong>：不满十八周岁的自然人为未成年人。&nbsp;十六周岁以上的未成年人，以自己的劳动收入为主要生活来源的，视为完全民事行为能力人。</p>
        <p>二、<strong>我们如何收集和使用您的个人信息</strong></p>
        <p>（一）&nbsp;您需授权我们收集和使用您个人信息的情形</p>
        <p>我们的产品/服务包括一些核心功能，这些功能包含了实现正常使用资讯浏览（赛事信息、数据与其他资讯）、信息发布（社区、球狮体育号）、互动交流（聊天室）、应用内消费所必须的功能，以及改进我们的产品与/或服务所必须的功能、保障交易安全所必须的功能。&nbsp;为实现上述功能，我们将基于您的授权同意，收集和使用一些与您有关的个人信息。如果您拒绝提供相关授权，可能将无法使用部分产品与/服务的功能。这些功能包括：
        </p>
        <ol>
            <li>注册</li>
        </ol>
        <p>若您想使用我们的产品/服务，您首先需要注册一个球狮体育账户。注册时，您需要向我们提供以下&nbsp;注册信息：<strong>您的手机号码&nbsp;（用于接收短信验证码）、&nbsp;您拟设置的球狮体育账户密码。</strong>
        </p>
        <ol>
            <li>实名认证</li>
        </ol>
        <p>依照相关法律法规的要求，您在使用特定功能及服务前，需要进行实名认证。基于您的授权同意或主动提供，我们将收集您的&nbsp;个人身份信息。根据国家有关法律法规，&nbsp;必须经过实名认证方可使用的具体功能包括：您在聊天室进行互动交流，您通过社区/球狮体育号进行信息发布，您通过球狮体育购买特定的产品或服务，您对社区/球狮体育号的预测方案作者进行支持，您针对球狮体育的资讯进行评论。
        </p>
        <p>当您第一次使用前述特定功能前，您需要按照系统提示提供您的&nbsp;个人身份信息与实名手机号码，&nbsp;且您同意我们自行或委托第三方向合法的、有资质的有关机构（如个人征信机构、政府机构等）提供、查询、&nbsp;核对您的身份真实性。如果您不提供个人身份信息，我们将不能向您提供上述功能。
        </p>
        <p>当然，我们对您进行实名认证，并不排除您对本人个人信息的保护义务。您有责任妥善保管您的账户信息，因您自身的原因导致账户或密码泄露，进而造成的相关个人信息的泄露、篡改、删除等后果，我们不承担任何责任。</p>
        <ol>
            <li>资讯浏览</li>
        </ol>
        <p>您可以通过搜索来精准地找到您所需要的资讯或信息。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品或服务。&nbsp;您使用搜索功能时，在搜索页面上将展示您剪贴板上最近一次的复制信息，以便为您快速提供您所需要的内容。&nbsp;请您放心，我们仅会对您的剪贴板最近一次复制的信息进行展示，不会收集您的剪贴板也不会将前述信息上传至我们的服务器。&nbsp;只有当您在搜索框中确认填入前述信息并点击搜索后，为了实现搜索功能我们会收集该信息。
        </p>
        <ol>
            <li>信息发布</li>
        </ol>
        <p>（1）您通过社区、球狮体育号及资讯评论功能进行内容发布时，我们将收集您发布的内容，并展示您的昵称、头像、发布内容、发布时间。</p>
        <ol>
            <li>互动交流</li>
        </ol>
        <p>您通过聊天室、互动档期参与互动交流时，我们将收集您发布的信息，并展示您的昵称、头像、发布内容、会员等级。</p>
        <ol>
            <li>应用内消费</li>
        </ol>
        <p>（1）下单</p>
        <p>当您准备通过球狮体育购买特定的虚拟财产（球币）时，球狮体育系统会生成您购买有关虚拟财产的订单。&nbsp;该订单中会载明您所购买的产品类型、您应支付的金额及可使用的支付方式。</p>
        <p>上述所有信息构成您的&ldquo;订单信息&rdquo;，我们将结合使用您的个人身份信息及您的订单信息，来为您提供身份核验、交易确认、支付结算、订单查询等服务&nbsp;，以及提供客服咨询与售后服务；我们还会使用您的订单信息来判断您的交易是否存在异常，以保护您的交易安全。
        </p>
        <p>（2）支付</p>
        <p>【针对Android用户】在您下单后，您可以选择与球狮体育合作的第三方支付机构&ldquo;支付宝支付&rdquo;或&ldquo;微信支付&rdquo;所提供的支付服务。&nbsp;您选择使用&ldquo;支付宝支付&rdquo;或&ldquo;微信支付&rdquo;，其支付功能本身并不收集您的个人信息，但我们需要将您的球狮体育订单号与交易金额信息与支付宝平台共享以实现其确认您的支付指令并完成支付。
        </p>
        <p>【针对iOS用户】在iOS系统的球狮体育客户端中，您必须使用IAP支付机制（即In-App&nbsp;Purchase）购买产品或服务。&nbsp;您应当在您个人的iOS设备上使用您常用的Apple&nbsp;ID进行操作，以确保交易的安全性。我们会收集您使用IAP支付的&nbsp;交易凭证和收费凭证，&nbsp;以确认交易状态及解决售后争议。iOS与其他系统类型的设备之间的虚拟资产是独立计算且不互通的。&nbsp;您使用iOS设备购买的相关虚拟资产，只能在iOS设备上使用。
        </p>
        <p>（3）交付产品</p>
        <p>在当您下单并选择在线完成支付后，我们将进行订单的交付。&nbsp;您知晓并同意我们将会在上述环节内使用您的订单信息以保证您的购买的虚拟财产能够安全完成交付。</p>
        <p>（4）客服与售后</p>
        <p>我们的电话/在线客服在服务过程和售后服务会使用您的账户信息和订单信息。</p>
        <p>为保证您的账号安全，我们的电话客服和在线客服会使用您的&nbsp;账户信息以核验您的身份。当您需要我们提供售前咨询或者与您订单信息相关的客服与售后服务时，我们将会查询您的&nbsp;访问记录或者&nbsp;订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息。
        </p>
        <p>当您与我们联系时，我们可能会保存与您的通信/通话记录和内容，或您留下的联系方式及相关信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
        <p>7.<strong>向您推送产品/服务本身所需的通知和消息</strong></p>
        <p>我们可能在必需时（例如当我们由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知；我们也可能通过移动设备系统推送内容等通知。我们与以下第三方平台就推送通知和信息功能展开合作：</p>
        <p>（1）友盟和bugly主要是用于统计和错误日志收集，帮助用户在闪退的时候能够进行问题的判别以及处理问题。</p>
        <p>（2）极光推送主要是推送服务，根据用户对于产品本身所需求提供的通知服务给予基础辅助。</p>
        <p>关于球狮体育合作的第三方服务收集、使用您个人信息的内容、范围与目的，详见&ldquo;八、第三方服务&rdquo;。</p>
        <p>如您不希望继续接收我们推送的通知或消息，您可通过球狮体育APP的&ldquo;我的-设置-推送设置&rdquo;停止向我们提供有关推送功能的授权。</p>
        <p>8.为您提供安全保障</p>
        <p>为提高您使用我们公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，&nbsp;更准确地识别违反法律法规或球狮体育相关协议规则的情况。&nbsp;我们可能使用或整合我们所收集的您的设备信息、网络信息，综合判断您的账户安全及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
        </p>
        <p>9.改进我们的服务</p>
        <p>若您向我们反馈您使用球狮体育过程中遇到的问题，经您同意，&nbsp;我们可能会收集您设备信息中&ldquo;最近的错误日志&rdquo;、并向您获取访问相册读、写权限/存储权限以获取截图信息，&nbsp;我们将通过您的反馈发现产品问题，对球狮体育进行改进和优化。&nbsp;为了保障软件与服务的稳定运行，更准确定位并解决您在使用软件时遇到的问题，改进及优化软件的服务体验，保障您的帐号安全，我们会收集您的硬件型号、操作系统版本号、设备序列号、国际移动设备识别码（IMEI）、国际移动用户识别码（IMSI）、设备应用安装列表、SD卡数据、剪切板、独立设备标识符（Android&nbsp;ID/IDFA/OpenUDID/GUID/OAID)。&nbsp;为了实现开屏广告摇一摇功能，我们会收集您的设备传感器数据（加速传感器）。
        </p>
        <p>10.<strong>找回账号、修改手机号</strong></p>
        <p>若您需要找回过往使用的球狮体育账号，经您同意，我们会将您的实名手机号码与人脸识别信息用于向合法的、&nbsp;有资质的合作方服务商验证您的身份。在此过程中，我们会收集人脸识别的验证结果，但不会收集、存储您的人脸识别信息。&nbsp;若您需要修改球狮体育账号绑定的手机号，经您同意，我们会收集您的手机号信息（包括账号绑定的原手机号与拟修改的新手机号），&nbsp;用于向合作方服务商验证您的身份。若您的账户绑定的原手机号目前无法使用，为了防止不法分子利用该功能盗取您的账号，&nbsp;经您同意，我们将会将您的人脸识别信息用于向合作方服务商验证您的身份。在此过程中，我们会收集人脸识别的验证结果，但不会收集、存储您的人脸识别信息。
        </p>
        <p>11.<strong>注销账号</strong></p>
        <p>若您需要注销您的球狮体育账号，您可以通过球狮体育APP-我的-设置-账号与安全-账号注销进行，有关注销的流程和说明，请参见点击&ldquo;账号注销&rdquo;之后的《注销风险说明》。为了防止不法分子利用该功能侵害您的权益，经您同意，&nbsp;我们会将您的个人身份信息和人脸识别信息用于向合作方服务商验证您的身份。在此过程中，&nbsp;我们会收集人脸识别的验证结果，但不会收集、存储您的人脸识别信息。
        </p>
        <ol>
            <li>其他用途</li>
        </ol>
        <p>请您注意，如果我们要将您的个人信息用于《隐私政策》中未载明的其他用途或额外收集《隐私政策》中未提及的您的其他个人信息，&nbsp;我们将以合理的方式（包括但不限于通过更新《隐私政策》、重新签署文件、页面提示、弹窗、站内信、邮件、网站公告或其他便于您获知的方式）&nbsp;另行向您详细说明对应信息的收集、使用目的、方式、范围等规则，在征得您的授权同意后收集和使用。&nbsp;一旦您同意，该等额外用途将视为《隐私政策》的一部分，该等额外个人信息的保护规则也将适用《隐私政策》。
        </p>
        <ol>
            <li>自启动和关联启动说明</li>
        </ol>
        <p>（一）为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。</p>
        <p>（二）为您打开球狮体育app内容推送消息，在征得您的明确同意后，会跳转球狮体育app打开相关内容，未征得您同意的情况下，则不会有关联启动。</p>
        <p>（三）当您打开球狮体育app内部下载的文件后，会关联启动第三方APP。</p>
        <p>(四)&nbsp;<strong>您可自主选择提供设备权限的情形</strong></p>
        <p>为提升您的使用体验，我们可能会基于您授权的移动设备操作系统相关权限来收集和使用您的个人信息。如果您不提供相关授权，您依然可以使用资讯浏览服务，但无法使用部分信息发布与互动交流服务。</p>
        <ol>
            <li>对于使用安卓系统的用户</li>
        </ol>
        <p>您在使用球狮体育时，球狮体育可能获取您终端设备的如下权限：</p>
        <p>（1）<strong>我们需要您的同意才能获得并使用存储权限，包括设备内存和SD卡的读取和写入（目的：您在申请球狮体育号发布权限，修改球狮体育账户头像，修改球狮体育号，保存图片使用）</strong></p>
        <p>（2）<strong>我们需要您的同意才能获得并使用设备信息权限，包括获得设备ID（AndroidID、OAID进程内获取一次）信息和手机号信息（目的：正常接收球狮体育推送的赛事信息、资讯等内容）</strong>
        </p>
        <p>（3）<strong>我们需要您的同意才能获得并使用相机权限（目的：拍照上传照片时使用，用于发帖及设置头像）</strong></p>
        <p>（4）<strong>系统将默认允许球狮体育获得网络连接权限（目的：与球狮体育服务器正常连接，获取数据）</strong></p>
        <p>（5）<strong>我们需要您的同意才能获得并使用麦克风权限（目的：录音时使用，用于发布录音帖）</strong></p>
        <p>（6）<strong>系统将默认允许球狮体育获取网络信息状态权限，如当前的网络连接是否有效（目的：检测网络状态运行环境，确保用户使用APP的所有功能正常）</strong></p>
        <p>（7）<strong>系统将默认允许程序获取当前WIFI接入的状态以及WLAN热点的信息（目的：检测无线网络的运行环境，确保用户在无线网络下正常使用球狮体育所有功能）</strong></p>
        <p>（8）<strong>在Flutter中，处理Android ID、剪切板描述信息、应用列表及硬件序列号通常是为了获取设备的唯一标识、访问剪切板内容、获取已安装应用列表或获取设备的硬件信息。</strong></p>
        <p>这些操作可能会用于以下目的：&nbsp;</p>
        <p>1.用户跟踪：追踪用户设备的唯一性，以便进行用户行为分析或个性化广告投放。</p>
        <p>2.数据保护：确保数据不会被复制或存储在多个设备上。</p>
        <p>3.应用内消息推送：根据设备ID向用户的特定设备推送消息。</p>
        <p>4.安全登录：通过硬件序列号提供额外的安全认证层级。</p>
        <ol>
            <li>对于使用iOS系统的用户</li>
        </ol>
        <p>您在使用球狮体育时，本软件可能获取您终端设备的如下权限：</p>
        <p>（1）<strong>我们需要您的同意才能使用访问相册读、写权限（目的：您在申请球狮体育号发布权限，修改球狮体育账户头像，保存图片使用）</strong></p>
        <p>（2）<strong>我们需要您的同意才能使用相机权限（目的：拍照上传照片时使用，用于发帖及设置头像）</strong></p>
        <p>（3）<strong>我们需要您的同意才能使用通知权限（目的：正常接收球狮体育推送的赛事信息、资讯等内容）</strong></p>
        <p>（4）<strong>系统将默认允许球狮体育获取设备系统时间（目的：与球狮体育服务器时间校对，提高数据准确性）</strong></p>
        <p>（5）<strong>若您使用的iOS系统版本为10.0及以上，我们需要您的同意才能获取网络信息权限；若您使用的iOS系统版本为10.0以下系统，系统将默认允许球狮体育获取网络信息权限（目的：检测网络状态运行环境，如确认当前的网络连接是否有效，确保用户使用球狮体育的所有功能正常等）</strong>
        </p>
        <p>（6）<strong>系统将默认允许球狮体育在手机屏幕关闭后后台进程仍可运行（目的：在息屏的情况下保持球狮体育正常运作，您可在系统设置中关闭）</strong></p>
        <p>(三)&nbsp;征得授权同意的例外</p>
        <p>根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：</p>
        <p>（1）与国家安全、国防安全有关的；</p>
        <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
        <p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
        <p>（4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
        <p>（5）所收集的信息是您自行向社会公众公开的；</p>
        <p>（6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>（7）根据您的要求签订合同所必需的；</p>
        <p>（8）用于维护球狮体育的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
        <p>（9）法律法规规定的其他情形。</p>
        <p>三、&nbsp;我们如何使用COOKIES或同类技术</p>
        <p>COOKIES或同类技术是互联网中普遍使用的技术。当您使用球狮体育时，我们可能会使用相关技术向您的设备发送一个或多个&nbsp;COOKIES或匿名标识符，以收集和存储您访问、使用我们的产品/服务时的信息，包括您访问网站的习惯、您的浏览信息、您的账户信息。
        </p>
        <p>通过使用COOKIES，我们向用户提供简单易行并富特色的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让用户可以受益，将主要用于以下用途：
        </p>
        <p>（1）<strong>记住您的身份。例如：COOKIES或同类技术有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；</strong></p>
        <p>（2）<strong>分析您使用我们服务的情况。我们可利用COOKIES或同类技术来了解您使用球狮体育进行什么活动、或哪些服务或服务最受欢迎。</strong></p>
        <p>我们承诺，不会将&nbsp;COOKIES用于《隐私政策》所述目的之外的任何其他用途。&nbsp;您可以通过浏览器或用户选择机制拒绝或管理COOKIES，具体请查阅您的浏览器附带的文件以获得更多这方面的信息。&nbsp;但请您注意，如果您停用或清除COOKIES，我们有可能无法为您提供最佳的服务体验，某些依赖于COOKIES的服务也可能无法正常使用。
        </p>
        <p>四、<strong>我们可能分享、转让或披露的信息</strong></p>
        <p>(一)分享</p>
        <p>未经您同意，我们不会与任何第三方分享您的信息，除非是该等信息经处理后的无法识别或关联到您的身份，&nbsp;且第三方无法重新识别此类信息的信息主体。</p>
        <p>我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享必要的个人信息。如果我们共享您的个人敏感信息，我们将会再次征求您的授权同意。&nbsp;如果第三方改变或超越您原授权同意范围使用您的个人信息，则我们会要求第三方再次征得您的授权同意。
        </p>
        <p>在与所有的第三方合作前，我们会严格要求合作伙伴履行必要的个人信息保护义务与责任，并签署关于数据安全的保护协议。</p>
        <p>在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他球狮体育用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，&nbsp;我们可能与政府机构和其他组织交换信息。不过，这并不包括违反《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露用户个人信息。
        </p>
        <p>1.<strong>为实现我们的服务/功能所必需进行的共享</strong></p>
        <p>如&ldquo;二、我们如何收集和使用信息&rdquo;所述，为了完成实名认证流程，核验您的身份真实性，我们会将收集的个人身份信息与合法的、有资质的合作方服务商共享。&nbsp;另外，为帮助您找回账号、修改手机号或注销球狮体育账号，&nbsp;且防止不法分子利用该等功能侵害您的权益，我们会将收集的手机号与合法的、有资质的合作方服务商共享，用于进一步核验您的身份真实性。
        </p>
        <p>2.<strong>与部分业务合作伙伴的必要共享</strong></p>
        <p>您理解并同意，为了必要/合理的业务开展、满足您的要求、履行我们在相关用户协议或《隐私政策》中的义务和行使我们的权利或遵守法律规定等目的，&nbsp;我们可能需要向部分业务合作伙伴共享您的部分信息。我们的业务合作伙伴包括：
        </p>
        <p>（1）提供营销服务（包括广告投放、市场营销）的合作伙伴</p>
        <p>为向您提供各类精彩内容及商业性信息的展示与通知，我们可能会与提供该服务的指定合作伙伴共享您的去标识化的设备信息，&nbsp;为了您的信息安全，我们目前仅与已签署严格数据安全保密协议的合作伙伴进行合作。</p>
        <p>（2）委托我们进行信息推广和广告投放的合作伙伴</p>
        <p>您授权我们有权与委托我们进行信息推广和广告投放的合作伙伴共享您的去标识化的设备信息，&nbsp;我们使用包括您在内的用户相关信息的集合形成的无法识别您或其他用户个人身份的间接用户画像、去标识化或匿名化处理后的分析/统计类信息，&nbsp;以帮助我们的合作伙伴在不识别用户身份的前提下进行广告或决策建议、提高广告有效触达率、衡量广告和相关服务的有效性。
        </p>
        <p>接入球狮体育的第三方SDK或其他类似应用程序将直接处理您的个人信息，具体收集范围、使用目的详见&ldquo;八、第三方服务&rdquo;。</p>
        <p>(二)&nbsp;转让</p>
        <p><strong>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</strong></p>
        <p>（1）事先获得您的明确同意或授权；</p>
        <p>（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求；</p>
        <p>（3）在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受《隐私政策》的约束，否则，我们将要求该公司、组织重新向您征求授权同意；</p>
        <p>（4）规定于本条&ldquo;（四）&nbsp;&nbsp;&nbsp;共享、转让、披露信息时事先征得授权同意的例外&rdquo;的其他情形。</p>
        <p>(三)&nbsp;<strong>披露</strong></p>
        <p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：</p>
        <p>（1）根据您的需求，在您明确同意的披露方式下披露您所指定的信息；</p>
        <p>（2）对违规账号、欺诈行为等进行处罚公告，例如，若您或您使用的球狮体育账户上传非法内容，或实施其他严重违反我们平台规则、&nbsp;法律法规规定的行为，我们可能会披露您的账户信息与处罚情况。</p>
        <p>（3）当您授权第三方游戏收集您的个人信息后，您的头像、昵称将会在游戏及相关功能中展示。</p>
        <p>（4）根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。&nbsp;在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。&nbsp;我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，&nbsp;且仅限于行政或司法部门因特定调查目的且有合法权利获取的数据。
        </p>
        <p>(四)<strong>&nbsp;共享、转让、披露信息时事先征得授权同意的例外</strong></p>
        <p>以下情形中，共享、转让、披露您的信息无需事先征得您的授权同意：</p>
        <p>（1）与国家安全、国防安全有关的；</p>
        <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
        <p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
        <p>（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p>（5）您自行向社会公众公开的信息；</p>
        <p>（6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道。</p>
        <p>根据法律规定，共享、转让经去标识化处理的信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
        <p>五、<strong>我们如何保留、储存信息</strong></p>
        <p>(一)保存期限</p>
        <p>我们仅在《隐私政策》所述目的所必需且最短的期间内，或法律法规要求的时限内保留您的信息。例如，当您使用我们的注册功能时，我们需要收集您的实名手机号码，&nbsp;且在您提供后并在您使用该功能期间，我们需要持续为您保留您的手机号码，以向您正常提供该功能、保障您的账号和系统安全。&nbsp;在超出上述存储期限后，或在您行使个人信息删除权、注销账号的情况下，我们会对您的个人信息进行删除或匿名化处理。
        </p>
        <p>但在下列情况下，我们有可能因需符合法律要求，调整个人信息的存储时间：</p>
        <p>（1）为了遵守适用的法律法规等有关规定（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）；</p>
        <p>（2）为了遵守法院判决、裁定或其他法律程序的规定；</p>
        <p>（3）为了遵守相关政府机关或法定授权组织的要求；</p>
        <p>（4）为维护社会公共利益，为保护我们的用户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。</p>
        <p>(二)停止运营</p>
        <p>如我们停止运营球狮体育产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以推送通知或公告的形式通知您，并在合理的期间内对所持有的个人信息进行删除或匿名化处理。</p>
        <p>(三)境内存储</p>
        <p>我们在中华人民共和国境内运营中收集和产生的信息，存储在中国境内，以下情形除外：</p>
        <p>（1）法律法规有明确规定；</p>
        <p>（2）获得您的授权同意；</p>
        <p>（3）您使用的产品、服务涉及跨境，我们需要向境外提供您的信息的。</p>
        <p>针对以上情形，我们会确保依据《隐私政策》及国家法律法规要求对您的个人信息提供足够的保护，且严格要求接收方按照《隐私政策》以及法律法规相关要求来处理您的个人信息。</p>
        <p>六、我们如何保护信息安全</p>
        <p>我们非常重视个人信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的信息。</p>
        <p>(一)数据安全技术措施</p>
        <p>我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。网络服务采取了多种加密技术。在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。
        </p>
        <p>(二)个人信息保护管理措施</p>
        <p>我们设立了个人信息保护专职部门，并通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理信息的存储和使用。</p>
        <p>我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</p>
        <p>我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。</p>
        <p>(三)保密措施</p>
        <p>我们仅允许有必要知晓这些信息的员工访问您的信息，并为此设置了严格的访问权限控制和监控机制。&nbsp;我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与球狮体育的合作关系。
        </p>
        <p>(四)安全事件处置</p>
        <p>为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，&nbsp;按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
        </p>
        <p>一旦不幸发生信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。&nbsp;我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。&nbsp;同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况
        </p>
        <p>请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入球狮体育所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</p>
        <p>互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。同时，我们将尽力确保或担保您发送给我们的任何信息的安全性。
        </p>
        <p>七、<strong>您对个人信息享有的权利</strong></p>
        <p>您可以在使用我们服务的过程中，访问、修改和注销您提供的账户信息和其他个人信息，如有疑问，也可以通过客服与我们联系。通过联系客服人员，查询、更新、提供或者注销个人信息。&nbsp;在您的球狮体育账号注销之后，我们将停止为您提供球狮体育服务，并依据您的要求，删除您的个人信息或做匿名化处理，但法律法规另有规定的除外。
        </p>
        <p>(一)<strong>个人信息查询、更正和删除</strong></p>
        <p>我们鼓励您更新/更正您的个人信息，以使其更准确有效。您能通过球狮体育访问您的信息，&nbsp;并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适当的技术手段或提供提交申请的联系渠道，&nbsp;尽可能保证您可以查询、更新/更正您的个人信息或使用球狮体育时提供的其他信息。&nbsp;如您想查询、修改或删除您的部分信息，请登录球狮体育进行相关操作，或者通过《隐私政策》中载明的联系方式联系我们进行处理。
        </p>
        <p>具体，您可以在我们产品中查询、更新/更正、删除您的以下个人信息：</p>
        <p>（1）您的个人账户信息（例如：头像、昵称等）、账户绑定的手机号、电子邮箱、账户绑定的第三方账号（微信、QQ）等。&nbsp;在球狮体育App中，您可以通过路径：&ldquo;我的-设置-个人资料&rdquo;查询，并对部分前述信息更新/更正或删除。
        </p>
        <p>在查询、更新/更正和删除账户绑定的手机号时，我们可能会要求您进行实名认证，以保障您的账户信息安全（具体详见&ldquo;二、我们如何收集和使用信息&rdquo;）。&nbsp;对于通过COOKIES或同类技术收集的您的信息，我们还在《隐私政策》第二条&ldquo;我们如何使用COOKIES或同类技术&rdquo;部分说明了向您提供的选择机制。
        </p>
        <p>(二)<strong>个人信息副本获取</strong></p>
        <p>如您需要您的个人信息的副本，您可以通过《隐私政策》&ldquo;十一、如何联系我们&rdquo;中提供的方式联系我们，在核实您的身份后，&nbsp;我们将向您提供您在我们的服务中的个人信息副本（包括您的身份信息、账户信息、订单信息和使用历史信息），&nbsp;但法律法规另有规定或《隐私政策》另有约定的除外。
        </p>
        <p>(三)&nbsp;<strong>改变您授权同意的范围</strong></p>
        <p>您总是可以选择是否披露信息。有些信息是使用我们的产品/服务所必需的，但大多数其他信息的提供是由您决定的。&nbsp;您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权。</p>
        <p>您可以在设备本身的操作系统中关闭位置、相机、麦克风、相册读、写权限/存储权限，改变您同意授权的范围或撤回您的授权。您撤回授权后，我们将不再收集与这些权限相关信息。但&nbsp;当您撤回有关授权后，我们无法继续为您提供这些授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。
        </p>
        <p>八、第三方服务</p>
        <p>(一)&nbsp;第三方SDK及其他类似应用程序</p>
        <p>为保障我们的客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，&nbsp;我们的应用中会嵌入业务合作伙伴的软件工具开发包（SDK）或其他类似的应用程序。&nbsp;我们会对业务合作伙伴获取信息的SDK、应用程序接口（API）进行严格的安全监测，&nbsp;令其按照《隐私政策》以及其他任何相关的保密和安全措施来处理个人信息，以保护数据安全。
        </p>
        <p>如您使用我们的产品/服务，您同意将由这些嵌入第三方SDK的合作伙伴直接收集和处理您的有关个人信息，&nbsp;且您已明确知悉：该等第三方SDK及其他类似应用程序由相关的第三方负责运营，&nbsp;其处理您的个人信息应当受其服务条款及个人信息保护声明（而非《隐私政策》）约束。
        </p>
        <p>第三方SDK目录</p>
        <p>为保障APP相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（SDK）实现相关目的。我们会对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。我们对接入的相关第三方SDK在目录中列明。
        </p>
        <p>（1）友盟统计SDK</p>
        <p>使用目的：统计用户使用产品或服务的活跃度</p>
        <p>使用场景：统计分析，性能检测</p>
        <p>收集方式：SDK采集</p>
        <p>收集个人信息类型：设备信息（IMEI/MAC/Android&nbsp;ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）、位置信息、网络信息</p>
        <p>公司名称：友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</p>
        <p>官网链接：<a href="https://www.umeng.com/">友盟+，国内领先的开发者服务及数据智能服务商</a></p>
        <p>隐私政策参见：<a href="https://www.umeng.com/policy">【友盟+】隐私政策</a></p>
        <p>（2）支付宝&nbsp;App&nbsp;支付客户端&nbsp;SDK</p>
        <p>使用目的：完成、实现平台付费业务交易</p>
        <p>使用场景：使用支付宝支付时使用</p>
        <p>收集方式：SDK采集</p>
        <p>收集数据类型：Android&nbsp;ID、OAID、SSID、BSSID；系统设置、系统属性、设备型号、设备品牌、操作系统；IP&nbsp;地址、网络类型、运营商信息、Wi-Fi&nbsp;状态、Wi-Fi&nbsp;参数、Wi-Fi&nbsp;列表
        </p>
        <p>公司名称：支付宝（中国）网络技术有限公司</p>
        <p>官网链接：<a href="https://www.alipay.com/">支付宝&ensp;知托付！</a></p>
        <p>隐私政策参见：<a href="https://opendocs.alipay.com/open/54/01g6qm">小程序文档&ensp;-&ensp;支付宝文档中心</a></p>
        <p>（3）微信支付SDK</p>
        <p>服务类型：用于微信支付购买</p>
        <p>收集方式：SDK采集</p>
        <p>收集个人信息类型:基础信息（设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息);设备标识(IMEI、MEID、OAID、Android&nbsp;ID、IMSI、ICCID、位置信息:IP地址、MAC地址、GPS位置信息、基站信息、WIFI信息);应用信息（应用安装列表);其他信息(传感器信息、sim卡激活信息)公司名称：腾讯科技（深圳）有限公司
        </p>
        <p>&nbsp;官网链接：<a href="https://weixin.qq.com/">微信，是一个生活方式</a></p>
        <p>隐私政策参见：<a href="https://privacy.qq.com/document/preview/dbd484ce652c486cb6d7e43ef12cefb0">腾讯隐私保护平台</a></p>
        <p>（4）智能认证SDK</p>
        <p>使用目的：帮助用户使用手机号一键登录</p>
        <p>使用场景：在用户注册登录时使用</p>
        <p>收集方式：SDK采集</p>
        <p>收集个人信息类型：手机号码、设备信息（IMEI/MAC/Android
            ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID/IP）、位置信息、网络信息公司名称：友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</p>
        <p>官网链接：<a href="https://www.umeng.com/">友盟+，国内领先的开发者服务及数据智能服务商</a></p>
        <p>隐私政策参见：<a href="https://www.umeng.com/policy">【友盟+】隐私政策</a></p>
        <p>（5）华为推送SDK</p>
        <p>使用目的：1、提供消息推送服务；2、统计华为推送SDK接口调用成功率</p>
        <p>使用场景：在推送消息时使用</p>
        <p>收集方式：SDK采集</p>
        <p>收集数据类型：应用基本信息（应用AppID、推送服务版本号、HMSCore版本号）、应用内设备标识符（AAID、PushToken、AndroidID）、设备的硬件信息（设备类型）、系统基本信息和系统设置信息（系统类型、系统版本）、传感器数据（加速传感器）、
        </p>
        <p>公司名称：华为软件技术有限公司</p>
        <p>官网链接：<a href="https://developer.huawei.com/consumer/cn/">华为开发者联盟-智能终端能力开放,共建开发者生态</a></p>
        <p>隐私政策参见：<a
                href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177">文档中心</a>
        </p>
        <p>&nbsp;&nbsp;&nbsp;(6)微博SDK</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三方名称：北京微梦创科网络技术有限公司</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;使用目的：登录及分享内容到新浪微博</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;处理方式：通过去标识化、加密传输和处理的安全处理方式</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;收集个人信息类型：基础信息（设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息)；设备标识(IMEI、MEID、OAID、Androidld、IMSI、ICCID、位置信息:IP地址、MAC地址、GPS位置信&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;息、基站信息、WIFI信息)；应用信息（应用安装列表）；其他信息（传感器信息、sim卡激活信息）
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三方隐私政策或官网链接：<a href="https://open.weibo.com/">新浪微博开放平台-首页</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)QQ&nbsp;SDK</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三方名称：腾讯科技（深圳）有限公司</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;使用目的：用于QQ账号登录服务、帮助用户分享内容至qq客户端</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;处理方式：通过去标识化、加密传输和处理的安全处理方式</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;收集个人信息类型：基础信息（设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息)；设备标识(IMEI、MEID、OAID、Androidld、IMSI、ICCID、位置信息:IP地址、MAC地址、GPS位&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;置信息、基站信息、WIFI信息)；应用信息（应用安装列表）；其他信息（传感器信息、sim卡激活信息）
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三方隐私政策或官网链接：<a
                href="https://privacy.qq.com/policy/tencent-privacypolicy">腾讯隐私保护平台</a></p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;(8)微信SDK</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三方名称：腾讯科技（深圳）有限公司</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;使用目的：用于微信账号登录服务、微信客服、帮助用户分享内容至微信客户端</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;处理方式：通过去标识化、加密传输和处理的安全处理方式</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;收集个人信息类型：基础信息（设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息)；设备标识(IMEI、MEID、OAID、Androidld、IMSI、ICCID、位置信息:IP地址、MAC地址、GPS位&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;置信息、基站信息、WIFI信息)；应用信息（应用安装列表）；其他信息（传感器信息、sim卡激活信息）
        </p>
        <p>&nbsp;&nbsp;&nbsp;（9）（自启动）规则</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;为提升消息的送达率，及时地为您进行消息提醒，我们会默认为您开启关联启动功能，以保持应用的活跃度。如您不想开通此功能，我们建议您手动进行关闭，一般关闭路径：设置&nbsp;-&nbsp;应用&nbsp;-&nbsp;应用启动管理&nbsp;-&nbsp;选择应用&ldquo;球狮体育&rdquo;-&nbsp;关闭关联启动功能
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;第三方隐私政策或官网链接：</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;https://privacy.qq.com/policy/tencent-privacypolicy</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;（10）小米推送SDK</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;应用场景描述：推送信息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收集的个人信息：访问网络、获取网络信息状态、获取WIFI状态、读取手机状态、写存储空间、未知应用安装权限、允许应用开启前台服务，设备标识OAID和加密的Android&nbsp;ID，以及使用推送服务的应用信息如应用包名、版本号和运行状态
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;信息类型：个人常用设备信息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;共享方式：SDK本机采集</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用目的：让小米设备能够收到推送消息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三方机构名称：北京小米移动软件有限公司</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私协议连接：https://dev.mi.com/console/doc/detail?pId=1822</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：https://dev.mi.com/console/</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;（11）vivo推送&nbsp;SDK</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;应用场景描述：推送信息</p>
        <p>&nbsp;&nbsp;收集数据类型：设备标识信息（如IMEI、EmmCID、UFSID、ANDROIDID、GUID、GAID、OPENID、VAID、OAID、RegID、加密的AndroidID）、使用推送服务的应用软件信息（如应用包名、版本号、APPID、安装、卸载、恢复出厂设置、运行状态）、设备制造商、网络类型、国家码、设备类型
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;信息类型：个人常用设备信息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;共享方式：SDK本机采集</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用目的：让vivo设备能够收到推送消息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三方机构名称：维沃移动通信有限公司</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私协议连接：https://www.vivo.com.cn/about-vivo/privacy-policy</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：https://push.vivo.com.cn</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;（12）oppo推送&nbsp;SDK</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;应用场景描述：推送信息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收集数据类型：设备相关信息（如IMEI或OAID，SerialNumber，IMSI，UserID，AndroidID，GoogleAdvertisingID,手机Region设置，设备型号，手机电量，手机操作系统版本及语言）、使用推送服务的应用信息（如APP包名及版本号，运行状态）、推送SDK版本号、网络相关信息（如IP或域名连接结果，当前网络类型）、消息发送结果、通知栏状态（如通知栏权限、用户点击行为），锁屏状态（如是否锁屏，是否允许锁屏通知）
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;信息类型：个人常用设备信息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;共享方式：SDK本机采集</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用目的：让oppo设备能够收到推送消息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三方机构名称：广东欢太科技有限公司</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私协议连接：https://open.oppomobile.com/wiki/doc#id=10288</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：https://push.oppo.com/</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(13)荣耀推送&nbsp;SDK</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;应用场景描述：推送信息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收集的个人信息：访问网络、获取网络信息状态、获取WIFI状态、读取手机状态、写存储空间、未知应用安装权限、允许应用开启前台服务</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;信息类型：个人常用设备信息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;共享方式：SDK本机采集</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用目的：让荣耀设备能够收到推送消息</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三方机构名称：华为技术有限公司</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私协议连接：https://www.hihonor.com/cn/privacy/privacy-policy/</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网链接：https://developer.hihonor.com/cn/tg/page/tg2023021410555618</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(14)阿里云推送SDK</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用目的:推送产品中用户自定义的推送内容,为了实现完整功能需要，极光服务在应用程序前后台都可能保持开启状态。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用场景:在推送消息时使用</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收集方式:&nbsp;SDK采集</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收集数据类型:设备标识符(IMEI、&nbsp;IDFA、&nbsp;Android&nbsp;ID.MAC、OAID、IMSI、&nbsp;UAID等相关信息)&nbsp;;应用信息(应用崩溃信息、通知开关状态、软件列表等相关信息)&nbsp;;设备参数及系统信息(设备类型、设备型号、操作系统及硬件相关信息)&nbsp;;网络信息(IP地址，WiFi信息)&nbsp;;基站信息等相关信息;地理位置(需用户授权)。
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司名称:阿里巴巴集团</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;隐私政策参见:&nbsp;https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html?spm=a2c4g.11186623.0.0.59873e06ELGil3
        </p>
        <p>（15）百度统计SDK</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;使用场景：统计分析</p>
        <p>收集方式：SDK采集</p>
        <p>收集数据类型：设备信息（IMEI/Mac/Android&nbsp;ID/IDFA/OpenUDID/GUID/SIM卡&nbsp;IMSI&nbsp;/IP），获取运行进程中信息，获取SDK数据，获取剪切板，地理位置（需用户授权）。
        </p>
        <p>公司名称：百度在线网络技术（北京）有限公司</p>
        <p>官网链接：<a href="https://tongji.baidu.com/web5/welcome/login">百度统计&mdash;&mdash;一站式智能数据分析与应用平台</a></p>
        <p>隐私政策参见：<a href="https://tongji.baidu.com/web/help/article?id=330">百度统计</a></p>
        <p>上述清单中示意&ldquo;可能收集的数据类型&rdquo;指的是：基于不同的&nbsp;SDK&nbsp;版本与不同的广告类型和广告主需求，由各广告SDK方自行调整的数据收集类型范围。</p>
        <p>（16）巨量引擎转化SDK</p>
        <p>收集方式：SDK采集</p>
        <p>用途和目的：基于广告的投放采集用户的广告行为、部分设备信息，以及开发者的应用程序内的部分转化事件，用于广告监测归因、投放效果优化等与广告活动相关的使用目的，不对其他第三方提供或披露相关数据。开发者对于相关信息的采集处理存储由开发者自行维护，不经由巨量引擎服务处理。
        </p>
        <p>个人信息类型与字段：【必要信息】</p>
        <p>（开发者与巨量引擎合作所需的基础信息）</p>
        <p>设备信息：</p>
        <p>【Android】设备品牌、型号、软件系统版本相关信息、oaid、设备IMEI、AndroidID、MEID、硬件序列号、系统属性、操作系统，无线网SSID名称、WIFI路由器MAC地址、设备的mac地址、IMSI、ICCID、WIFI状态等基础信息
        </p>
        <p>【IOS】IDFA、IDFV、设备品牌、型号、软件系统版本相关信息、手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目、系统属性、操作系统等</p>
        <p>基础信息应用信息 ：开发者应用名、应用包名、版本号等信息</p>
        <p>其他信息： 运营商信息、设备时区</p>
        <p>转化事件</p>
        <p>合作方式：提供数据统计和分析服务</p>
        <p>官网链接：<a href="https://www.oceanengine.com/">巨量引擎官网｜今日头条广告投放_抖音广告投放_抖音推广</a></p>
        <p>隐私政策参见：<a href="https://www.oceanengine.com/extra/legal">巨量引擎法律声明_巨量引擎隐私条款_巨量引擎隐私政策 - 巨量引擎官网</a></p>
        <p>（17）使用的SDK名称：直播推流SDK</p>
        <p>服务类型：用于app内的直播功能</p>
        <p>服务提供方：阿里云计算有限公司/百年云启（上海）网络科技有限公司</p>
        <p>收集的各个信息类型：UUID，设备型号，IP地址，wifi状态，运营商信息</p>
        <p>SDK隐私政策链接：https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112151438_20307.html&nbsp;
        </p>
        <p>（18）使用的SDK名称：网易七鱼SDK</p>
        <p>服务类型：提供客服会话服务</p>
        <p>服务提供方：杭州网易质云科技有限公司</p>
        <p>收集的各个信息类型：采集网络信息（网络状态、wifi信息，IP地址），用于了解设备的网络状态和变化，从而最大程度保持网络连接的稳定性；当开启传感器信息权限后，会收集距离传感器信息，用于感知手机是否被举耳边，提供更好的语音聊天体验。&nbsp;
        </p>
        <p>SDK隐私政策链接：<a href="https://qi.163.com/termsService?privacy=1">网易云商-服务营销一体化平台</a></p>
        <p>(19)SDK名称:networkSDK</p>
        <p>SDK使用目的:让设备能够收到推送消息</p>
        <p>使用场景:推送消息</p>
        <p>收集方式:SDK采集收集</p>
        <p>数据类型:设备标识符(IMEI、IDFA、Android
            ID.MAC、OAID、IMSI、UAID等相关信息):应用信息(应用崩溃信息、通知开关状态、软件列表等相关信息);设备参数及系统信息(设备类型、设备型号、操作系统及硬件相关信息);网络信息(IP地址，WiFi信息):基站信息等相关信息:地理位置(需用户授权)。
        </p>
        <p>公司名称:阿里巴巴集团隐私政策参见:https://terms.aliyun.com/legal-agreement/terms/suit bul alcloud/suit bul ali
            cloud202112071754 83380.html?spm=a2c4g.11186623.0.0.59873e06FLG;13日</p>
        <p>(20)SDK名称:Firebasecommon</p>
        <p>SDK口使用目的:让设备能够收到推送消息</p>
        <p>使用场景:推送消息</p>
        <p>收集方式:SDK采集</p>
        <p>收集数据类型:设备标识符(MEI、IDEA、Android
            ID,MAC、OAID、IMSI、UAID等相关信息);应用信息(应用崩溃信息、通知开关状态、软件列表等相关信息);设备参数及系统信息(设备类型、设备型号、操作系统及硬件相关信息);网络信息(IP地址，WiFi信息):基站信息等相关信息:地理位置(需用户授权)。
        </p>
        <p>公司名称:谷歌&nbsp;</p>
        <p>隐私政策参见:https://terms.aliyun.com/egal-agreement/terms/suit bul alicloud/suit bul ali cloud202112071754
            83380.html?spm=a2c4g.11186623.0.0.59873e06ELGi13</p>
        <p>(21)岳鹰SDK</p>
        <p>运营公司名称：广州市动景计算机科技有限公司</p>
        <p>使用目的：崩溃监测与性能监测</p>
        <p>收集的信息类型：设备信息（UUID、设备品牌、设备型号、设备ROM版本）、网络信息（IP地址、网络类型、网络状态）、时间戳、应用信息（应用包名、应用版本号）、开发者主动上传的数据（自定义标识、日志）</p>
        <p>隐私政策链接：<a href="https://yueying-docs.effirst.com/privacy.html">岳鹰SDK隐私政策 | 岳鹰全景监控</a></p>
        <p>(22)中国移动sdk&nbsp;</p>
        <p>运营公司名称：中移互联网有限公司</p>
        <p>使用目的：通过运营商通道，为相应运营商用户提供一键登录服务</p>
        <p>收集的信息类型：运营商信息、设备标识信息、本机号码、设备硬件信息、操作系统信息、网络信息</p>
        <p>(23)中国联通sdk</p>
        <p>运营公司名称：中国联合网络通信有限公司</p>
        <p>使用目的：通过运营商通道，为相应运营商用户提供一键登录服务</p>
        <p>收集的信息类型：运营商信息、设备标识信息、本机号码、设备硬件信息、操作系统信息、网络信息</p>
        <p>(24)中国电信sdk</p>
        <p>运营公司名称：世纪龙信息网络有限责任公司</p>
        <p>使用目的：通过运营商通道，为相应运营商用户提供一键登录服务</p>
        <p>收集的信息类型：运营商信息、设备标识信息、本机号码、设备硬件信息、操作系统信息、网络信息</p>
        <p>(25)阿里通信sdk</p>
        <p>运营公司名称：阿里巴巴云计算（北京）有限公司</p>
        <p>使用目的：通过运营商通道，为相应运营商用户提供一键登录服务</p>
        <p>收集的信息类型：网络类型、设备信息（含IP地址、设备制造商、设备型号、手机操作系统、SIM卡信息ICCID）</p>
        <p>九、未成年人个人信息保护条款</p>
        <p>(一)&nbsp;若您是未成年人</p>
        <p>若您是未成年人，在使用我们的产品/服务前，应在您的家长或法定监护人（以下统称"监护人"）同意和指导下共同阅读并确认《隐私政策》。</p>
        <p>对于经监护人同意使用我们的产品或服务的未成年人，若其不满十四周岁，且我们收集该等未成年人的个人信息，则该等个人信息属于儿童个人信息。&nbsp;我们只会在法律法规允许、监护人明确、单独同意或者保护未成年人所必要的情况下使用、共享、转让或披露。
        </p>
        <p>(二)&nbsp;若您是未成年人的监护人</p>
        <p>我们建议：任何未成年人参加网上活动应事先取得监护人的同意。我们鼓励监护人指导未成年人使用球狮体育。</p>
        <p>我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、监护人明确、单独同意或保护未成年人所必要的情况下收集、使用、储存、共享、&nbsp;转让或披露未成年人的个人信息；如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。
        </p>
        <p>当您对您所监护的未成年人的个人信息有相关疑问时，请通过《隐私政策》公示的联系方式与我们联系。</p>
        <p>(三)&nbsp;我们针对未成年人个人信息保护的具体措施</p>
        <p>为保护未成年人个人信息的安全，并提供更加丰富、多元的优质内容，我们开发了&ldquo;青少年模式&rdquo;。在该模式下，我们将挖掘更多的适合未成年人浏览的优质内容，为监护人提供更丰富的管理手段，并建立严格的未成年人个人信息收集、使用规则，确保个人信息收集、使用在最小限度与范围内。
        </p>
        <ol>
            <li>如果您的孩子是未满14周岁的儿童，他/她使用球狮体育的普通模式，需要经过您的同意；如果您不同意他/她使用球狮体育的普通模式，请您为他/她设置&ldquo;青少年模式&rdquo;。</li>
            <li>如果您的孩子是已满14周岁不满18周岁的未成年人，请您根据自主需要，选择是否为您的孩子设置&ldquo;青少年模式&rdquo;。</li>
        </ol>
        <p>十、通知和修订</p>
        <p>（一）我们可能适时修改《隐私政策》的条款，该等修改构成《隐私政策》的一部分。我们会在我们的产品/服务内公布《隐私政策》的更新版本，或以其他适当方式提醒您相关内容的更新。</p>
        <p>（二）我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与提供服务有关的公告。您可能无法取消这些与提供服务有关、性质不属于推广的公告。</p>
        <p>您可以在我们的产品/服务中查阅《隐私政策》及其他相关协议的最新版本。您有权不接受修改后的《隐私政策》，但可能会导致您需要停止使用我们的产品/服务。</p>
        <p>特别声明</p>
        <p>鉴于《中华人民共和国民法典》第四百九十六条第二款规定：采用格式条款订立合同的，&nbsp;提供格式条款的一方应当遵循公平原则确定当事人之间的权利和义务，并采取合理的方式提示对方注意免除或者减轻其责任等与对方有重大利害关系的条款，&nbsp;按照对方的要求，对该条款予以说明。提供格式条款的一方未履行提示或者说明义务，&nbsp;致使对方没有注意或者理解与其有重大利害关系的条款的，对方可以主张该条款不成为合同的内容。我们依法作出特别声明如下：
        </p>
        <p>球狮体育采取合理的方式提请您注意的义务将通过如下方式实现：在《隐私政策》中已明确提醒用户注意相关条款（需要强调的是，您应特别注意任何未明确标记但含有&ldquo;不承担&rdquo;、&ldquo;免责&rdquo;、&ldquo;不得&rdquo;等形式用语的条款），&nbsp;请您在确认同意《隐私政策》之前再次阅读上述条款。您确认，上述条款不属于《民法典》第四百九十六条规定的&ldquo;免除或者减轻其责任等&rdquo;的条款。
        </p>
        <p>十一、如何联系我们</p>
        <p>我们的产品/服务由广西知音文化传媒有限公司运营，产品/服务中涉及具体第三方服务内容的，则由有资质的服务商提供，&nbsp;并按照其服务条款及个人信息保护声明（而非《隐私政策》）保护您的信息。&nbsp;如您有关于个人信息安全的投诉和举报，或您对《隐私政策》、您的个人信息的处理等事宜有任何问题、意见或建议，请与我们联系：
        </p>
        <p>个人信息保护专员：&nbsp;您可发送邮件至：120329298@qq.com</p>
        <p>侵害未成年人权益举报邮箱：120329298@qq.com</p>
        <p>十二、APP运营者基本信息</p>
        <p>信息保护人联系电话：13260023777</p>
    </div>
</template>
<script>
export default {
}
</script>
