<template>
    <div>
        <p><strong>球狮体育（以下简称&ldquo;我们&rdquo;）尊重并保护球狮体育的未成年人个人信息。除&nbsp;</strong><strong>《隐私政策》</strong><strong>&nbsp;和针对特地产品与/或服务的隐私政策外，我们还根据《中华人民共和国网络安全法》、《未成年网络保护条例》等相关法律规定，制定了《未成年人隐私保护协议》（以下简称&ldquo;本协议&rdquo;）。</strong>
        </p>
        <p><strong>本协议构成《隐私政策》一部分，并在《隐私政策》基础上，对未成年人信息需要加强保护的部门做出增强说明和协议。我们将按照本协议收集、使用、存储及共享未成年人的个人信息。如果未成年人个人信息保护政策方面与《隐私政策》有不一致的，以本协议为准。</strong>
        </p>
        <p><strong>向您特别提示：如您的孩子将使用本协议未提及的球狮体育产品与/或服务的功能的，请参考《隐私政策》中个人信息收集和使用部分的相关内容，但建议在您的陪同与监护下进行操作或者由您直接代其操作。</strong>
        </p>
        <p><strong>本协议包含了我们收集、使用、共享、存储和保护未成年人个人信息的条款，我们建议由监护人（以下简称&ldquo;您&rdquo;）完整地阅读本协议，需要时，按照本协议作出您认为适合并能够保护您的孩子权益的选择。</strong>
        </p>
        <p><strong>我们不会制作、发布、传播侵害未成年人个人信息安全的信息。我们将遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，收集存储、使用、转移、披露未成年个人信息。&nbsp;</strong><strong>如果您不同意本协议的任何内容，我们将无法为您的孩子提供球狮体育应用（见关键词定义）的产品与/或服务，（以下简称&ldquo;球狮体育产品与/或服务&rdquo;），您应引导您的孩子立即停止使用球狮体育产品与/或服务。当本协议更新后，我们会及时地在球狮体育应用上发布最新的版本，建议您及时地关注和阅读。</strong>
        </p>
        <p><strong>如对本协议或相关事宜有任何问题，您可以通过我们的未成年人信息保护负责人邮箱：120329298@</strong><strong>qq</strong><strong>.com</strong><strong>与我们取得联系。</strong>
        </p>
        <p><strong>本协议的要点如下：</strong></p>
        <p><strong>本协议向您说明每项业务功能收集的您的孩子个人信息的类型、收集目的、收集方式，以及拒绝提供您的孩子个人信息可能导致的结果。</strong></p>
        <p><strong>本协议将严格保障未成年人权利，包括支持更正、删除等保护您的孩子个人信息的措施。</strong></p>
        <p><strong>为保护未成年人个人信息安全，本协议将向您说明我们所采用的安全策略及技术措施。我们为未成年人提供符合行业要求的个人信息安全保护水平。</strong></p>
        <p><strong>我们将依法及本协议规定收集、使用您的孩子的个人信息，如果超过本协议的所述范围收集、使用您的孩子的个人信息，我们会再次向您告知并征得您本人同意。</strong></p>
        <p><strong>本隐私政策包含以下内容：</strong></p>
        <p><strong>一、如何收集和使用您的孩子的个人信息</strong></p>
        <p><strong>二、如何使用Cookie及相关技术</strong></p>
        <p><strong>三、您的孩子个人信息的分享</strong></p>
        <p><strong>四、如何委托处理、共享、转让、对外披露您的孩子的个人信息</strong></p>
        <p><strong>五、如何存储您的孩子的个人信息</strong></p>
        <p><strong>六、如何保护您的孩子的个人信息</strong></p>
        <p><strong>七、本协议的变更</strong></p>
        <p><strong>一、如何收集和使用您的孩子的信息</strong></p>
        <p><strong>我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。</strong></p>
        <p><strong>在您的孩子使用球狮体育产品与/或服务的过程中，可能会主动提供信息，我们也可能收集您的孩子因为使用球狮体育产品与/或服务而产生的信息，用于向您的孩子提供球狮体育产品与/或服务、优化球狮体育产品与/或服务以及保障账号安全。</strong>
        </p>
        <p><strong>1、注册功能</strong></p>
        <p><strong>您理解并同意，如您的孩子希望使用球狮体育产品与/或服务，首先需要注册一个球狮体育账户成为球狮体育APP的注册会员。在注册时，您和/或您的孩子需要向我们提供手机号码，我们将通过拨打语音电话的方式来验证手机号码是否有效。您的孩子在您授权之下也可以登录球狮体育产品与/或服务。</strong>
        </p>
        <p><strong>为满足相关法律法规和相关主管部门要求，您的孩子需进行</strong><strong>实名认证</strong><strong>以继续使用球狮体育产品与/或服务，我们会在实名认证情况下收集您的孩子的</strong><strong>身份证信息、姓名，</strong><strong>该信息属于敏感信息，拒绝提供实名身份信息会导致您的孩子无法登录或无法使用球狮体育产品的相关功能。</strong>
        </p>
        <p><strong>您的孩子还可以通过登录您的孩子所注册的与我们合作的第三方平台（例如微信、QQ等）的相关账户使用球狮体育产品与/或服务，此时我们会从第三方平台共享&nbsp;</strong><strong>您的孩子在第三方账号项下的相关信息，包括手机账户、账户昵称（但不包括实名认证信息等）。</strong><strong>&nbsp;就第三方平台所收集的您的孩子的个人信息，适用于第三方平台的隐私政策或相关声明。</strong>
        </p>
        <p><strong>为教育引导您的孩子增强个人信息保护意识和能力，保护您的孩子的个人信息安全，我们强烈建议上述注册过程由您来帮助您的孩子完成。</strong></p>
        <p><strong>2、账号安全提示功能</strong></p>
        <p><strong>为保障您的孩子的账号安全，在出现异常登录情形时，我们将通过语音电话的形式进行提示，为此，我们将自动收集您的孩子在使用球狮体育产品与/或服务时的IP地址。</strong></p>
        <p><strong>3、信息展示和搜索功能</strong></p>
        <p><strong>为了让您的孩子快速地找到其所需要的球狮体育产品与/或服务，我们可能会收集您的孩子使用球狮体育产品与/或服务的&nbsp;</strong><strong>个人常用设备信息</strong><strong>&nbsp;，包括手机硬件型号、操作系统类型、设备识别码，来为您的孩子提供商品信息展示的最优方式。&nbsp;</strong><strong>您知悉并同意，为了不管改进还优化球狮体育产品与/或服务的功能，我们可能使用您的孩子上述个人信息。</strong><strong>&nbsp;此外，我们会保留您的孩子的搜索内容以减少重复输入的次数来为您的孩子展示与其搜索内容相关联的内容。若您的孩子以游客身份所有球狮体育产品与/或服务，我们只向其展示热门推荐的球狮体育产品与/或服务。</strong>
        </p>
        <p><strong>请您注意，您的孩子搜索的信息无法单独识别您的孩子的身份，不属于个人信息，我们有权以任何目的对其进行使用；只有当您的孩子搜索的信息与您的孩子的其他信息相互结合使用并可以识别您的孩子的身份时，则在结合使用期间，我们会将您的孩子的搜索的信息作为个人信息，与您的孩子的搜索历史记录一同按照本隐私政策对其进行处理与保护。</strong>
        </p>
        <p><strong>4、客服与客诉</strong></p>
        <p><strong>在您/您的孩子与我们的在线客服与/或电话客服进行沟通时，为了及时有效地与您/您的孩子沟通，我们的在线客服和电话客服会要求您提供您/您的孩子的</strong><strong>账号信息</strong><strong>以核验身份。</strong>
        </p>
        <p><strong>当您的孩子忘记账号需要找回时，您/您的孩子需要向我们提供您的孩子的：&nbsp;</strong><strong>身份证信息、姓名、手机账号、角色昵称，以便我们进行查核判断。</strong></p>
        <p><strong>当您的孩子希望注销账号时，您/您的孩子需要向我们提供您的孩子的：&nbsp;</strong><strong>身份证信息、姓名、手机账号以及账号的昵称。</strong></p>
        <p><strong>当您的孩子希望解除账号封停时，您/您的孩子需要向我们提供您的孩子的：&nbsp;</strong><strong>身份证信息、姓名、手机账号、被封原因、以及保证信息。</strong></p>
        <p><strong>收集上述个人身份信息的目的是为了确认账号归属，收集账号信息的目的是为了完成相应的业务操作。&nbsp;</strong><strong>如发现您的孩子与我们进行联系，请您及时告知我们，我们会向您的孩子提示在您的陪同监护下进行，或请您来与我们进行沟通。</strong>
        </p>
        <p><strong>5、设备权限调用</strong></p>
        <p><strong>我们在提供球狮体育产品与/或服务的过程中，可能需要开启一些设备访问权限，相关权限调用的具体情况详见《隐私政策》。</strong></p>
        <p><strong>二、如何使用Cookie及相关技术</strong></p>
        <p><strong>我们使用Cookies来保持球狮体育产品与/或服务的可靠性、安全性和个性化。您可以通过阅读《隐私政策内Cookies政策》了解具体内容。</strong></p>
        <p><strong>我们也会使用我们自研SDK和第三方SDK实现信息的自动采集和使用，相关收集信息的内容，频率以及使用目的请参见</strong><strong>《用户隐私政策》</strong><strong>。</strong>
        </p>
        <p><strong>三、您的孩子个人信息的分享</strong></p>
        <p><strong>1、球狮体育产品/或服务，可让您的孩子不仅与自己的社交网络，也与使用该球狮体育产品与/或服务的其他用户公开分享您的孩子的相关信息，例如，您的孩子在球狮体育产品与/或服务中所上传或发布的信息（包括您的孩子公开的个人信息）、您的孩子对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。此外，您在使用球狮体育产品与/或服务时自动提供、上传或发布的内容和信息，可能会包含您的孩子的个人信息，请您谨慎操作。</strong>
        </p>
        <p><strong>2、为教育和引导您的孩子增强个人信息保护意识和能力，保护您的孩子的个人信息安全，我们强烈建议3.1条所述功能由您来操作，并请您谨慎考虑，是否在使用球狮体育产品与/或服务时自行主动披露您的孩子的个人信息。</strong>
        </p>
        <p><strong>3、您可用过球狮体育产品与/或服务中的在线客服联系方式，主动联系我们注销删除您的孩子的个人信息。但请您注意，这些信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或存储，或由其他用户或第三方在公共领域保存。</strong>
        </p>
        <p><strong>四、如何委托处理、共享、转让、对外披露您的孩子的个人信息</strong></p>
        <p><strong>1、委托处理、共享</strong></p>
        <p><strong>根据法律法规的规定，我们对信息的委托处理、共享进行严格的限制。除以下情形外，我们不会委托任何第三方或与第三方共享您的孩子的个人信息。</strong></p>
        <p><strong>1.1获得您的同意或授权</strong></p>
        <p><strong>1.2应您的合法要求而提供您的孩子的个人信息</strong></p>
        <p><strong>1.3我们可能会委托我们的关联公司、第三方合作伙伴（第三方产品与/或服务供应商、承包商、代理商、广告合作伙伴、应用开发者、支付服务应用等，例如代表我们推送通知通讯产品与/或服务提供商）处理或者与这些第三方共享您的孩子的个人信息，例如：账号信息、登录设备等。</strong>
        </p>
        <p><strong>当我们委托第三方处理您的孩子个人信息的，我们会根据法律、行政法规的要求，自行或委托第三方机构进行安全评估。我们还会要求他们签署协议，按照我们的说明、隐私政策以及其它相关保密和安全措施来为我们处理上述信息，并使用户实现本协议所述目的。如要改变您的孩子个人信息的处理目的，该第三方将再次征求您的授权同意。</strong>
        </p>
        <p><strong>我们仅会出于合法、正当、必要的目的向上述第三方提供您的孩子的个人信息，并且只会提供相关球狮体育产品与/或服务所必要的个人信息，我们还会在处理或共享的过程中采用加密、去标识化手段保障您的孩子的信息安全。</strong>
        </p>
        <p><strong>2、对外披露</strong></p>
        <p><strong>除非法律、行政法规规定应当披露或根据与您的约定可以披露，我们不会披露您的孩子的个人信息。</strong></p>
        <p><strong>五、如何存储您的孩子的个人信息</strong></p>
        <p><strong>1、信息存储的方式和期限</strong></p>
        <p><strong>我们会通过安全的加密方式安全存储您的孩子的个人信息，包括本地存储（例如利用APP进行数据缓存）、数据库和产品与/或服务器的日志。</strong></p>
        <p><strong>我们只会在达成本协议所述目的所必须的最短期限内保留您的孩子的个人信息，其中当我们作为数据控制者而提供的球狮体育产品与/或服务予以下架时，该款球狮体育产品与/或服务内的数据（包括行为数据，账号资料等数据）将会被删除进行处理，但国家法律法规等另有要求或者为履行我们的合规义务而保留您的孩子的个人信息的除外。</strong>
        </p>
        <p><strong>2、信息存储的地域</strong></p>
        <p><strong>原则上，我们不会将您的孩子的个人信息传输至境外，我们仅在中国境内运营收集和产生的您的孩子的个人信息存储在中国境内。如将来存在出境需求的，我们将在根据法律法规的要求做出个人信息影响评估后，向您通知，并征得您的再次同意。</strong>
        </p>
        <p><strong>3、您如何管理您的孩子的个人信息</strong></p>
        <p><strong>1、除根据《隐私政策》及针对特定球狮体育产品与/或服务的特定隐私政策中的权利外，您可以通过控制您的孩子的账户，更改、删除您的孩子的个人信息，以保护您的孩子的信息安全及身心健康。</strong></p>
        <p><strong>2、如您/您的孩子发现我们收集、存储、使用、披露的未成年个人信息有误，您可以发送邮件至120329298@qq.com向我们申请监护服务。</strong></p>
        <p><strong>3、在以下情况下，您可以要求我们删除您的孩子的个人信息。</strong></p>
        <p><strong>3.1我们违反法律、行政法规的规定或者双方的约定收集、存储、使用、转移、披露您的孩子个人信息的；</strong></p>
        <p><strong>3.2我们超出目的范围或者必要期限收集、存储、使用、转移、披露你孩子个人信息的；</strong></p>
        <p><strong>3.3您已撤回同意的</strong></p>
        <p><strong>3.4您/您的孩子通过注销账户等方式终止使用球狮体育产品/或服务的。</strong></p>
        <p><strong>六、如何保护您的孩子的个人信息</strong></p>
        <p><strong>1、为保障您的孩子的信息安全，我们会在现有技术水平下努力采取合理必要的物理、技术和行政管理方面的安全措施来保护您的孩子的信息，以防止您的孩子的信息遭受违法复制、丢失、误用、非授权访问、下载、披露和更改，包括但不限于SSL、信息加密存储、数据中心的访问控制、专用网络通道及代理。</strong>
        </p>
        <p><strong>2、我们建立专门的管理制度、流程、组织并采取相应措施确保信息安全。对于工作人员，我们将以最小授权为原则，严格设定信息访问权限，控制未成年人个人信息的知悉范围。如需访问未成年人信息，我们将要求经过未成年人信息保护责任人或其授权的管理人员审批，并对访问情况进行记录。此外，我们对可能接触到您的孩子个人信息的员工或外包人员进行严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们操作情况等措施；组织对员工的数据安全或合规的培训等。</strong>
        </p>
        <p><strong>七、本协议的变更</strong></p>
        <p><strong>我们可能适时修订本协议的条款，该等修改构成本协议的一部分。对于实质性变化（如处理您的孩子的个人信息的目的、方式和范围等），我们将在修订生效前通过在球狮体育应用的显著位置提示或以其他方式发布对本协议所做的变更，以再次征得您的同意。如您不同意修订后的本协议，我们将无法继续向您/您的孩子提供球狮体育产品/或服务。</strong>
        </p>
    </div>
</template>
<script>
export default {
}
</script>