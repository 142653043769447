import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from "@/views/homePage.vue"
import PayPolicy from "@/views/qiushi/payPolicy.vue"
import PrivacyPolicy from "@/views/qiushi/privacyPolicy.vue"
import SavePolicy from "@/views/qiushi/savePolicy.vue"
import SdkShareList from "@/views/qiushi/sdkShareList.vue"
import UserPolicy from "@/views/qiushi/userPolicy.vue"
import VipService from "@/views/qiushi/vipService.vue"

const routes = [
  {
    path: "/",
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: "/qiushi/payPolicy",
    name: 'PayPolicy',
    component: PayPolicy,
  },
  {
    path: "/qiushi/privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/qiushi/savePolicy",
    component: SavePolicy,
  },
  {
    path: "/qiushi/sdkShareList",
    component: SdkShareList,
  },
  {
    path: "/qiushi/userPolicy",
    component: UserPolicy,
  },
  {
    path: "/qiushi/vipService",
    component: VipService,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
