<template>
    <div>
        <p><strong>（1）友盟统计SDK</strong></p>
        <p><span>使用目的：统计用户使用产品或服务的活跃度</span></p>
        <p><span>使用场景：统计分析，性能检测</span><span>&nbsp;</span></p>
        <p><span>收集方式：SDK采集</span></p>
        <p><span>收集个人信息类型：设备信息（IMEI/MAC/Android&nbsp;ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）、位置信息、网络信息</span></p>
        <p><span>公司名称：友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</span></p>
        <p><span>官网链接：</span><a href="https://www.umeng.com/"><span>友盟+，国内领先的开发者服务及数据智能服务商</span></a></p>
        <p><span>隐私政策参见：</span><a href="https://www.umeng.com/policy"><span>【友盟+】隐私政策</span></a></p>
        <p><strong>（2）支付宝&nbsp;App&nbsp;支付客户端&nbsp;SDK</strong></p>
        <p><span>使用目的：完成、实现平台付费业务交易</span></p>
        <p><span>使用场景：使用支付宝支付时使用</span></p>
        <p><span>收集方式：SDK采集</span></p>
        <p><span>收集数据类型：Android&nbsp;ID、OAID、SSID、BSSID；系统设置、系统属性、设备型号、设备品牌、操作系统；IP&nbsp;地址、网络类型、运营商信息、Wi-Fi&nbsp;状态、Wi-Fi&nbsp;参数、Wi-Fi&nbsp;列表</span>
        </p>
        <p><span>公司名称：支付宝（中国）网络技术有限公司</span></p>
        <p><span>官网链接：</span><a href="https://www.alipay.com/"><span>支付宝&ensp;知托付！</span></a></p>
        <p><span>隐私政策参见：</span><a
                href="https://opendocs.alipay.com/open/54/01g6qm"><span>小程序文档&ensp;-&ensp;支付宝文档中心</span></a></p>
        <p><strong>（3）微信支付SDK</strong></p>
        <p><span>服务类型：用于微信支付购买</span></p>
        <p><span>收集方式：SDK采集</span></p>
        <p><span>收集个人信息类型:基础信息（设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息);设备标识(IMEI、MEID、OAID、Android&nbsp;ID、IMSI、ICCID、位置信息:IP地址、MAC地址、GPS位置信息、基站信息、WIFI信息);应用信息（应用安装列表);其他信息(传感器信息、sim卡激活信息)</span>
        </p>
        <p><span>公司名称：腾讯科技（深圳）有限公司</span></p>
        <p><span>官网链接：</span><a href="https://weixin.qq.com/"><span>微信，是一个生活方式</span></a></p>
        <p><span>隐私政策参见：</span><a
                href="https://privacy.qq.com/document/preview/dbd484ce652c486cb6d7e43ef12cefb0"><span>腾讯隐私保护平台</span></a>
        </p>
        <p><strong>（4）智能认证SDK</strong></p>
        <p><span>使用目的：帮助用户使用手机号一键登录</span></p>
        <p><span>使用场景：在用户注册登录时使用</span></p>
        <p><span>收集方式：SDK采集</span></p>
        <p><span>收集个人信息类型：手机号码、设备信息（IMEI/MAC/Android&nbsp;ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）、位置信息、网络信息公司名称：友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</span>
        </p>
        <p><span>官网链接：</span><a href="https://www.umeng.com/"><span>友盟+，国内领先的开发者服务及数据智能服务商</span></a></p>
        <p><span>隐私政策参见：</span><a href="https://www.umeng.com/policy"><span>【友盟+】隐私政策</span></a></p>
        <p><strong>（5）华为推送SDK</strong></p>
        <p><span>使用目的：1、提供消息推送服务；2、统计华为推送SDK接口调用成功率</span></p>
        <p><span>使用场景：在推送消息时使用</span></p>
        <p><span>收集方式：SDK采集</span></p>
        <p><span>收集数据类型：应用基本信息（应用AppID、推送服务版本号、HMSCore版本号）、应用内设备标识符（AAID、PushToken、AndroidID）、设备的硬件信息（设备类型）、系统基本信息和系统设置信息（系统类型、系统版本）、传感器数据（加速传感器）、</span>
        </p>
        <p><span>公司名称：华为软件技术有限公司</span></p>
        <p><span>官网链接：</span><a
                href="https://developer.huawei.com/consumer/cn/"><span>华为开发者联盟-智能终端能力开放,共建开发者生态</span></a></p>
        <p><span>隐私政策参见：</span><a
                href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177"><span>文档中心</span></a>
        </p>
        <p><strong>（6）微博SDK</strong></p>
        <p><span>第三方名称：北京微梦创科网络技术有限公司</span></p>
        <p><span>使用目的：登录及分享内容到新浪微博</span></p>
        <p><span>处理方式：通过去标识化、加密传输和处理的安全处理方式</span></p>
        <p><span>收集个人信息类型：基础信息（设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息)；设备标识(IMEI、MEID、OAID、Androidld、IMSI、ICCID、位置信息:IP地址、MAC地址、GPS位置信&nbsp;息、基站信息、WIFI信息)；应用信息（应用安装列表）；其他信息（传感器信息、sim卡激活信息）</span>
        </p>
        <p><span>第三方隐私政策或官网链接：</span><a href="https://open.weibo.com/"><span>新浪微博开放平台-首页</span></a></p>
        <p><strong>（7）QQ&nbsp;SDK</strong></p>
        <p><span>第三方名称：腾讯科技（深圳）有限公司</span></p>
        <p><span>使用目的：用于QQ账号登录服务、帮助用户分享内容至qq客户端</span></p>
        <p><span>处理方式：通过去标识化、加密传输和处理的安全处理方式</span></p>
        <p><span>收集个人信息类型：基础信息（设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息)；设备标识(IMEI、MEID、OAID、Androidld、IMSI、ICCID、位置信息:IP地址、MAC地址、GPS位&nbsp;置信息、基站信息、WIFI信息)；应用信息（应用安装列表）；其他信息（传感器信息、sim卡激活信息）</span>
        </p>
        <p><span>第三方隐私政策或官网链接：</span><a
                href="https://privacy.qq.com/policy/tencent-privacypolicy"><span>腾讯隐私保护平台</span></a></p>
        <p><strong>（8）微信SDK</strong></p>
        <p><span>第三方名称：腾讯科技（深圳）有限公司</span></p>
        <p><span>使用目的：用于微信账号登录服务、微信客服、帮助用户分享内容至微信客户端</span></p>
        <p><span>处理方式：通过去标识化、加密传输和处理的安全处理方式</span></p>
        <p><span>收集个人信息类型：基础信息（设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息)；设备标识(IMEI、MEID、OAID、Androidld、IMSI、ICCID、位置信息:IP地址、MAC地址、GPS位&nbsp;置信息、基站信息、WIFI信息)；应用信息（应用安装列表）；其他信息（传感器信息、sim卡激活信息）</span>
        </p>
        <p><strong>（9）（自启动）规则</strong></p>
        <p><span>为提升消息的送达率，及时地为您进行消息提醒，我们会默认为您开启关联启动功能，以保持应用的活跃度。如您不想开通此功能，我们建议您手动进行关闭，一般关闭路径：设置&nbsp;-&nbsp;应用&nbsp;-&nbsp;应用启动管理&nbsp;-&nbsp;选择应用&ldquo;球狮体育&rdquo;-&nbsp;关闭关联启动功能</span>
        </p>
        <p><span>第三方隐私政策或官网链接：</span></p>
        <p><a href="https://privacy.qq.com/policy/tencent-privacypolicy"><span>腾讯隐私保护平台</span></a></p>
        <p><strong>（10）小米推送SDK</strong></p>
        <p><span>应用场景描述：推送信息</span></p>
        <p><span>收集的个人信息：访问网络、获取网络信息状态、获取WIFI状态、读取手机状态、写存储空间、未知应用安装权限、允许应用开启前台服务，设备标识OAID和加密的Android&nbsp;ID，以及使用推送服务的应用信息如应用包名、版本号和运行状态</span>
        </p>
        <p><span>信息类型：个人常用设备信息</span></p>
        <p><span>共享方式：SDK本机采集</span></p>
        <p><span>使用目的：让小米设备能够收到推送消息</span></p>
        <p><span>第三方机构名称：北京小米移动软件有限公司</span></p>
        <p><span>隐私协议连接：https://dev.mi.com/console/doc/detail?pId=1822</span></p>
        <p><span>官网链接：https://dev.mi.com/console/</span></p>
        <p><strong>（11）vivo推送&nbsp;SDK</strong></p>
        <p><span>应用场景描述：推送信息</span></p>
        <p><span>收集数据类型：设备标识信息（如IMEI、EmmCID、UFSID、ANDROIDID、GUID、GAID、OPENID、VAID、OAID、RegID、加密的AndroidID）、使用推送服务的应用软件信息（如应用包名、版本号、APPID、安装、卸载、恢复出厂设置、运行状态）、设备制造商、网络类型、国家码、设备类型</span>
        </p>
        <p><span>信息类型：个人常用设备信息</span></p>
        <p><span>共享方式：SDK本机采集</span></p>
        <p><span>使用目的：让vivo设备能够收到推送消息</span></p>
        <p><span>第三方机构名称：维沃移动通信有限公司</span></p>
        <p><span>隐私协议连接：https://www.vivo.com.cn/about-vivo/privacy-policy</span></p>
        <p><span>官网链接：https://push.vivo.com.cn</span></p>
        <p><strong>（12）oppo推送&nbsp;SDK</strong></p>
        <p><span>应用场景描述：推送信息</span></p>
        <p><span>收集数据类型：设备相关信息（如IMEI或OAID，SerialNumber，IMSI，UserID，AndroidID，GoogleAdvertisingID,手机Region设置，设备型号，手机电量，手机操作系统版本及语言）、使用推送服务的应用信息（如APP包名及版本号，运行状态）、推送SDK版本号、网络相关信息（如IP或域名连接结果，当前网络类型）、消息发送结果、通知栏状态（如通知栏权限、用户点击行为），锁屏状态（如是否锁屏，是否允许锁屏通知）</span>
        </p>
        <p><span>信息类型：个人常用设备信息</span></p>
        <p><span>共享方式：SDK本机采集</span></p>
        <p><span>使用目的：让oppo设备能够收到推送消息</span></p>
        <p><span>第三方机构名称：广东欢太科技有限公司</span></p>
        <p><span>隐私协议连接：https://open.oppomobile.com/wiki/doc#id=10288</span></p>
        <p><span>官网链接：https://push.oppo.com/</span></p>
        <p><strong>（13）荣耀推送&nbsp;SDK</strong></p>
        <p><span>应用场景描述：推送信息</span></p>
        <p><span>收集的个人信息：访问网络、获取网络信息状态、获取WIFI状态、读取手机状态、写存储空间、未知应用安装权限、允许应用开启前台服务</span></p>
        <p><span>信息类型：个人常用设备信息</span></p>
        <p><span>共享方式：SDK本机采集</span></p>
        <p><span>使用目的：让荣耀设备能够收到推送消息</span></p>
        <p><span>第三方机构名称：华为技术有限公司</span></p>
        <p><span>隐私协议连接：https://www.hihonor.com/cn/privacy/privacy-policy/</span></p>
        <p><span>官网链接：https://developer.hihonor.com/cn/tg/page/tg2023021410555618</span></p>
        <p><strong>（14）阿里云号码认证SDK</strong></p>
        <p><span>服务类型：本机号码一键登录和认证。</span></p>
        <p><span>收集数据类型：设备标识符（IMEI/Mac/androidID/IDFA/OPENUDID/GUID/SIM卡IMSI信息/ICCID）以及手机号码。</span></p>
        <p><span>隐私权政策链接：</span><a
                href="https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112211045_86198.html?spm=a2c4g.11186623.0.0.5e6f633egcdizf"><span>号码认证服务隐私权政策</span></a>
        </p>
        <p><strong>（15）阿里云推送SDK</strong></p>
        <p><span>使用目的:推送产品中用户自定义的推送内容,为了实现完整功能需要，极光服务在应用程序前后台都可能保持开启状态。</span></p>
        <p><span>使用场景:在推送消息时使用</span></p>
        <p><span>收集方式:&nbsp;SDK采集</span></p>
        <p><span>收集数据类型:设备标识符(IMEI、&nbsp;IDFA、&nbsp;Android&nbsp;ID.MAC、OAID、IMSI、&nbsp;UAID等相关信息)&nbsp;;应用信息(应用崩溃信息、通知开关状态、软件列表等相关信息)&nbsp;;设备参数及系统信息(设备类型、设备型号、操作系统及硬件相关信息)&nbsp;;网络信息(IP地址，WiFi信息)&nbsp;;基站信息等相关信息;地理位置(需用户授权)。</span>
        </p>
        <p><span>公司名称:阿里巴巴集团</span></p>
        <p><span>隐私政策参见:&nbsp;https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html?spm=a2c4g.11186623.0.0.59873e06ELGil3</span>
        </p>
        <p><strong>（16）百度统计SDK</strong></p>
        <p><span>使用场景：统计分析</span></p>
        <p><span>收集方式：SDK采集</span></p>
        <p><span>收集数据类型：设备信息（IMEI/Mac/Android&nbsp;ID/IDFA/OpenUDID/GUID/SIM卡&nbsp;IMSI&nbsp;/IP），获取运行进程中信息，获取SDK数据，获取剪切板，地理位置（需用户授权）。</span>
        </p>
        <p><span>公司名称：百度在线网络技术（北京）有限公司</span></p>
        <p><span>官网链接：</span><a
                href="https://tongji.baidu.com/web5/welcome/login"><span>百度统计&mdash;&mdash;一站式智能数据分析与应用平台</span></a>
        </p>
        <p><span>隐私政策参见：</span><a href="https://tongji.baidu.com/web/help/article?id=330"><span>百度统计</span></a>
        </p>
        <p><span>&nbsp;</span></p>
        <p><span>&nbsp;&nbsp;共享个人信息字段定义及穷举</span></p>
        <p><span>设备MAC地址:设备的MAC地址，确认网上设备的地址</span></p>
        <p><span>唯一设备识别码:&nbsp;OAID/IMEI/androidID/IDFA/OPENUDID/GUID/SIM卡/IMSI信息</span></p>
        <p><span>设备IP地址:移动互联网连接协议，确认网络连接的运营商服务</span></p>
        <p><span>设备信息:设备品牌、设备型号、操作系统、操作系统版本</span></p>
        <p><span>网络信息:所处网络环境，如Wi-Fi,5G,4G,3G,2G</span></p>
        <p><span>运营商信息:移动、联通、电信</span></p>
    </div>
</template>
<script>
</script>