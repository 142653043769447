<template>
    <div class="title">首页</div>
</template>
<script>
export default {
  name: 'HomePage'
}
</script>
<style>
.title{
  font-weight: 600;
  text-align: center;
}
</style>